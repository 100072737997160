import axios, { AxiosResponse, ParamEncoder } from 'axios';
import { API_BASE_URL } from '../../config/constants';
axios.defaults.withCredentials = true;
export const getUserFeedbackAPI = async (params: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/user-feedback`,
    method: 'GET',
    params: params,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}
export const destroyUserFeedbackAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/user-feedback/${id}`,
    method: 'DELETE',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}




