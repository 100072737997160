import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import { Button, Dropdown } from "react-bootstrap";
import Paginator from "../../components/ui-components/pagination/Paginator";
import useTitle from "../../hooks/useTitle";
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import { destroyContactSupport, getContactSupport } from "../../store/contactSupport/contactSupportSlice";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formateDate } from "../../helper/basic";
function ContactSupport(props: Props) {
    useTitle('Contact Support');
    const columns: Array<Column> = [
        { label: "User Details", accessor: "user_details" },
        { label: "Message", accessor: "message" },
        { label: "Location", accessor: "location" },
        { label: "Order Number", accessor: "orderNumber" },
        { label: "User Type", accessor: "userType" },
        { label: "Newsletter", accessor: "newsletterSubscription" },
        { label: "Attachments", accessor: "attachments" },
        { label: "Actions", accessor: "actions" },
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const contactSupport = useAppSelector((state) => state.contactSupport.all);
    const totalPages = useAppSelector((state) => state.contactSupport.totalPages);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    const [modalShow, setModalShow] = useState(false);

    const [attachments, setAttachments] = useState<string[]>([])
    useEffect(() => {
        const params = { query: query, pageNumber: pageNumber };
        if (!token) return;
        dispatch(getContactSupport({ params, token }));
    }, [pageNumber, query]);

    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
    }

    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyContactSupport({ id, token }));
            }
        });
    }
    const onView = (images: string[]) => {
        setModalShow(true);
        setAttachments(images);
    }
    const onHide = () => {
        setModalShow(false);
        setAttachments([]);
    }
    return (

        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Name or Number ..." onSearchInputChange={onSearchInputChange}>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="slot-modal-vcenter"
                centered
                onHide={() => onHide()}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="slot-modal-vcenter" as="h5">
                        Attachments
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <a >D</a>
                    <div className="d-flex gap-2 flex-wrap">
                        {attachments && attachments.map((image, index) => {
                            return (
                                <div key={index} className="d-flex flex-column gap-3">
                                    <img src={image} height={"200px"} />
                                    <a href={image} download={image} title="image" target="_black" className="btn btn-sm btn-success">Download</a>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button type="submit" size="sm" onClick={() => onHide()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <h2 className="main-title">Contact Support</h2>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {
                                        contactSupport && contactSupport.map((contactSupport) => {
                                            return (
                                                <tr key={contactSupport?._id}>
                                                    <td width={"18%"}>
                                                        <h5>{contactSupport?.fullName}</h5>
                                                        {contactSupport?.email ? <><b className="me-3">Email:</b> {contactSupport?.email}<br /> </> : null}
                                                        <span className="me-3 fw-normal">Phone:</span >{contactSupport?.dialCode}-{contactSupport?.phoneNumber}<br />
                                                    </td>
                                                    <td width={"30%"}>
                                                        <div className="pe-2 ps-2">

                                                            <h6>Subject: {contactSupport?.subject} </h6>
                                                            {contactSupport?.message}

                                                            <p className="text-success">
                                                                At {formateDate(contactSupport?.createdAt)} 
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {contactSupport?.location}<br />
                                                    </td>
                                                    <td>
                                                        {contactSupport?.orderNumber}<br />
                                                    </td>
                                                    <td className="text-capitalize">
                                                        {contactSupport?.role}<br />
                                                    </td>
                                                    <td className="text-capitalize">
                                                        {contactSupport?.newsletterSubscription ? "Subscribed" : "Not Subscribed"}<br />
                                                    </td>
                                                    <td>
                                                        {
                                                            contactSupport.attachments.length !== 0 ?
                                                                <Button size="sm" variant="primary" className="rounded-pill ps-3 pe-3" onClick={(e) => onView(contactSupport.attachments)} >
                                                                    View
                                                                </Button> : "No attachments"

                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-2">
                                                            <Link to={`mailto:${contactSupport?.email}?subject=${contactSupport?.subject}&body=Hello ${contactSupport?.fullName}!&cc=&bcc=`} className="rounded-pill ps-3 pe-3 btn btn-primary" >
                                                                Reply
                                                            </Link>
                                                            <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(contactSupport?._id)} >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {(contactSupport && contactSupport.length !== 0) ? null : <NoDataFound colSpan={8} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default ContactSupport;