import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { getFoodItemsAPI, getFoodItemAPI, storeFoodItemAPI, destroyFoodItemAPI, updateFoodItemAPI } from './foodItemApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";

export interface IFoodItem {
  all: Array<any>,
  pageNo: number,
  totalPages: number,
  totalResources?: number,
  singleFoodItem: Record<string, any> | null,
}

const initialState: IFoodItem = {
  all: [],
  pageNo: 1,
  totalPages: 1,
  totalResources: 0,
  singleFoodItem: null,
};

export enum FoodItemTypePrefix {
  GET_SINGLE = "food-item/get/single",
  GET_ALL = "food-item/get",
  STORE = "food-item/store",
  DESTROY = "food-item/destroy",
  UPDATE = "food-item/update",
}

export const foodItemSlice = createSlice({
  name: "foodItem",
  initialState: initialState,
  reducers: {
    setFoodItems: (state, action: PayloadAction<any>) => {
      state.all = action.payload.results;
      state.pageNo = action.payload.page_no;
      state.totalPages = action.payload.total_pages;
      state.totalResources = action.payload.total_resources;
    },
    removeFoodItem: (state, action: PayloadAction<any>) => {
      const foodID = action.payload;
      state.all = state.all.filter(item => item._id !== foodID);
    },
    setSingleFoodItem: (state, action: PayloadAction<any>) => {
      state.singleFoodItem = action.payload;
    },
  },
});


export const getFoodItems = createAsyncThunk<AxiosResponse, { params: Record<string, any>, token: string }, { rejectValue: string }>(
  FoodItemTypePrefix.GET_ALL,
  async ({ params, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getFoodItemsAPI(params, token)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setFoodItems(response));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

// export const getZone = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
//   FoodItemTypePrefix.GET_SINGLE,
//   async ({ id, token }, thunkAPI) => {
//     try {
//       thunkAPI.dispatch(startLoading());
//       const response: any = await getZoneAPI(id, token)
//       if (response.status) {
//         /**
//          * Success
//          */
//         thunkAPI.dispatch(setSingleFoodItem(response.results));
//         return response;
//       } else {
//         const err = response as any;
//         thunkAPI.dispatch(errorAlert(err.message))
//         return thunkAPI.rejectWithValue('');
//       }
//     } catch (error) {
//       const err = error as AxiosError;
//       thunkAPI.dispatch(errorAlert(err.message));
//       return thunkAPI.rejectWithValue(err.message);
//     }
//     finally {
//       thunkAPI.dispatch(completeLoading())
//     }
//   }
// );

export const storeFoodItem = createAsyncThunk<AxiosResponse, { data: FormData, token: string }, { rejectValue: string }>(
  FoodItemTypePrefix.STORE,
  async ({ data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await storeFoodItemAPI(data, token);
      if (response.status) {
        /**
         * Success
         * action
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const destroyFoodItem = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  FoodItemTypePrefix.DESTROY,
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await destroyFoodItemAPI(id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        thunkAPI.dispatch(removeFoodItem(id));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const updateFoodItem = createAsyncThunk<AxiosResponse, { data: FormData, id: string, token: string }, { rejectValue: string }>(
  FoodItemTypePrefix.UPDATE,
  async ({ id, data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await updateFoodItemAPI(data, id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);


export const { setFoodItems, setSingleFoodItem, removeFoodItem } = foodItemSlice.actions;

export default foodItemSlice.reducer;
