import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { destroyDriverAPI, getDriverAPI, getDriversAPI, updateDriverAPI, getTransactionsAPI } from './driverApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";
export interface ITransactionState {
  all: Array<any>;
  pageNo: number,
  totalPages: number,
  totalResources?: number,
}

export interface IDriverState {
  all: Array<any>;
  pageNo: number,
  totalPages: number,
  totalResources?: number,
  singleDriver: Record<string, any> | null,
  transactions: ITransactionState,
}

const initialState: IDriverState = {
  all: [],
  pageNo: 1,
  totalPages: 1,
  totalResources: 0,
  singleDriver: null,
  transactions: {
    all: [],
    pageNo: 1,
    totalPages: 1,
    totalResources: 0,
  }
};

export const driverSlice = createSlice({
  name: "driver",
  initialState: initialState,
  reducers: {
    setDriver: (state, action: PayloadAction<any>) => {
      state.all = action.payload.results;
      state.pageNo = action.payload.page_no;
      state.totalPages = action.payload.total_pages;
      state.totalResources = action.payload.total_resources;
    },
    removeDriver: (state, action: PayloadAction<any>) => {
      const driverID = action.payload;
      state.all = state.all.filter(item => item._id !== driverID);
    },
    setSingleDriver: (state, action: PayloadAction<any>) => {
      state.singleDriver = action.payload;
    },
    setTransaction: (state, action: PayloadAction<any>) => {
      state.transactions.all = action.payload.results;
      state.transactions.pageNo = action.payload.page_no;
      state.transactions.totalPages = action.payload.total_pages;
      state.transactions.totalResources = action.payload.total_resources;
    },
  },
});


export const getDrivers = createAsyncThunk<AxiosResponse, { params: Record<string, any>, token: string }, { rejectValue: string }>(
  'driver/get',
  async ({ params, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getDriversAPI(params, token)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setDriver(response));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const getDriver = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  'user/get',
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getDriverAPI(id, token)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setSingleDriver(response?.results));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);
export const destroyDriver = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  'driver/destroy',
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await destroyDriverAPI(id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        thunkAPI.dispatch(removeDriver(id));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const updateDriver = createAsyncThunk<AxiosResponse, { data: Record<string, any>, id: string, token: string }, { rejectValue: string }>(
  'timeSlot/destroy',
  async ({ id, data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await updateDriverAPI(data, id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);


export const getTransaction = createAsyncThunk<AxiosResponse, { params: Record<string, any>, token: string }, { rejectValue: string }>(
  'driver/destroy',
  async ({ params, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getTransactionsAPI(params, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        thunkAPI.dispatch(setTransaction(response))
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);


export const { setDriver, removeDriver, setSingleDriver, setTransaction } = driverSlice.actions;

export default driverSlice.reducer;
