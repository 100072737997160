import "./NotFound404.css";
import "../../assets/css/Button.css";
import not_found_svg from "../../assets/img/svg/404.svg"
import useTitle from "../../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
function NotFound404() {
    useTitle('404 Not Found');
    const navigate = useNavigate();
    return (
        <div className="not-found">
            <img src={not_found_svg}></img>
            <h4>404 Not Found</h4>
            <p>Your Request resulted in an error.</p>
            <Button variant="primary-default" className="button" onClick={() => navigate(-1)}>
                Back to Home
            </Button>
        </div>
    )
}

export default NotFound404;