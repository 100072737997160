import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import ThemeTable, { Column } from "../../components/ui-components/table/ThemeTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NoDataFound } from "../../components/ui-components/table/ThemeTable";
import useTitle from "../../hooks/useTitle";
import { Link } from "react-router-dom";
import Paginator from "../../components/ui-components/pagination/Paginator";
import { getTransaction } from "../../store/driver/driverSlice";
import { formateDate } from "../../helper/basic";
function DriverTransactions(props: Props) {
    useTitle('Driver Transactions');
    const columns: Array<Column> = [
        { label: "Transaction ID", accessor: "transaction_id" },
        { label: "Driver Details", accessor: "driver_details" },
        { label: "Tip", accessor: "tip" },
        { label: "Per trip Amount", accessor: "per_trip_amount" },
        { label: "Total", accessor: "total" },
        { label: "Status", accessor: "status" },
        { label: "Date & Time", accessor: "date_time" },
        { label: "Payment Summary", accessor: "payment_summary" },
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const transactions = useAppSelector((state) => state.drivers.transactions.all);
    const { totalPages } = useAppSelector((state) => state.drivers.transactions);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    useEffect(() => {
        const params = { query: query, pageNumber: pageNumber };
        if (!token) return;
        dispatch(getTransaction({ params, token }))
    }, [pageNumber, query]);

    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
    }
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Order ID ..." onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2 className="main-title mb-0">Driver Transactions</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {
                                        transactions && transactions.map((transaction: any) => {
                                            return (
                                                <tr key={transaction?._id}>
                                                    <td width={"8%"} className="pe-2">
                                                        <h6 className="me-3" title={transaction?._id}>
                                                            {transaction?.transactionID ? transaction?.transactionID : "N/A"}
                                                            {" "}
                                                            ({transaction?.slotsRef?.name ? transaction?.slotsRef?.name : "N/A"})
                                                        </h6>
                                                        {transaction?.striptTransfersID !== "" ? <p>Stripe TransactionID:: {transaction?.striptTransfersID} </p> : null}
                                                    </td>
                                                    <td>
                                                        {
                                                            transaction?.driversRef ?
                                                                <div>
                                                                    <Link to={`/drivers/${transaction?.driversRef?._id}`}><b>{transaction?.driversRef?.fullName}</b></Link>
                                                                    <br />
                                                                    {transaction?.driversRef?.dialCode}-{transaction?.driversRef?.phoneNumber}
                                                                </div>
                                                                : 'N/A'
                                                        }
                                                    </td>
                                                    <td width={"4%"}>
                                                        {
                                                            transaction?.tip ?
                                                                <p>
                                                                    C$ <b>{transaction?.tip}</b>
                                                                </p> : <>
                                                                    C$ <b>0</b>
                                                                </>
                                                        }
                                                    </td>
                                                    <td width={'10%'}>
                                                        {
                                                            transaction?.perTripAmount ?
                                                                <p>
                                                                    C$ <b>{transaction?.perTripAmount}</b>
                                                                </p> : <>
                                                                    C$ <b>0</b>
                                                                </>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            transaction?.amount ?
                                                                <p>
                                                                    C$ <b>{transaction?.amount}</b>
                                                                </p> : <>
                                                                    C$ <b>0</b>
                                                                </>
                                                        }

                                                    </td>
                                                    <td>
                                                        {
                                                            ['initiated',].includes(transaction?.transactionStatus) ? <span className="app-badge badge-active">{transaction?.transactionStatus}</span> : null
                                                        }
                                                        {
                                                            ['pending',].includes(transaction?.transactionStatus) ? <span className="app-badge badge-pending">{transaction?.transactionStatus}</span> : null
                                                        }
                                                        {
                                                            ['succeeded'].includes(transaction?.transactionStatus) ? <span className="app-badge badge-success">{transaction?.transactionStatus}</span> : null
                                                        }
                                                        {
                                                            ['failed'].includes(transaction?.transactionStatus) ? <span className="app-badge badge-trashed">{transaction?.transactionStatus}</span> : null
                                                        }
                                                    </td>
                                                    <td width={"20%"}>
                                                        <p>{formateDate(transaction?.createdAt)}</p>
                                                    </td>
                                                    <td width={"20%"}>
                                                        <small>
                                                            <b>Description</b>: <br />
                                                            {transaction?.description ? transaction?.description : ''}
                                                        </small>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {(transactions && transactions.length !== 0) ? null : <NoDataFound colSpan={9} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div >
            </main >
        </MainLayout >
    )
}

export default DriverTransactions;