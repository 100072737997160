import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

interface LatLng {
    lat: number;
    lng: number;
}

interface LeafletMapProps {
    center: any;
    zoom: number;
    markers: LatLng[];
}

const LeafletMap: React.FC<LeafletMapProps> = ({ center, zoom, markers }) => {
    const mapRef = useRef<L.Map | null>(null);
    return (
        //@ts-ignore
        <MapContainer style={{ height: '400px', width: '100%' }} zoom={zoom} center={center}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {markers.map((marker, index) => (
                <Marker key={index} position={marker} >
                    <Popup>A Marker</Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default LeafletMap;