import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import ThemeTable, { Column } from "../../components/ui-components/table/ThemeTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { useEffect, useState } from "react";
import { NoDataFound } from "../../components/ui-components/table/ThemeTable";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';
import useTitle from "../../hooks/useTitle";
import { destroyCuisine, getCuisines, storeCuisine, updateCuisine } from "../../store/cuisine/cuisineSlice";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import Swal from "sweetalert2";
import { formateDate } from "../../helper/basic";
function Cuisine(props: Props) {
    useTitle("Restaurant Type");
    const columns: Array<Column> = [
        { label: "Name", accessor: "name" },
        { label: "Created", accessor: "createdAt" },
        { label: "Status", accessor: "status" },
        { label: "Actions", accessor: "actions" },
    ];

    const initialValues = {
        name: '',
        isPublished: false,
    }

    const [inputs, setInputs] = useState(initialValues)
    const { Formik } = formik;

    const validationSchema = yup.object().shape({
        name: yup.string().required("Cuisine name is a required field."),
        // isPublished: yup.bool().oneOf([true, false], 'Field must be checked'),
    });

    const dispatch = useAppDispatch();
    const cuisines = useAppSelector((state) => state.cuisines.all);
    const token = useAppSelector((state) => state.auth.accessToken);
    const [modalShow, setModalShow] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [cuisineID, setCuisineID] = useState("");
    useEffect(() => {
        dispatch(getCuisines({ params: {} }));
    }, []);

    useEffect(() => {

    }, [cuisineID])
    const onEdit = (id: string) => {
        setCuisineID(id);
        setModalShow(true);
        setEditMode(true);
        const singleCuisine = cuisines.find(item => item._id === id);
        setInputs({
            ...inputs,
            name: singleCuisine?.name,
            isPublished: singleCuisine?.isPublished
        });
    }

    const onCreate = () => {
        setInputs(initialValues);
        setEditMode(false);
        setModalShow(true);
    }
    const onHide = () => {
        setModalShow(false);
        setEditMode(false);
        setInputs(initialValues);
    }
    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!token) return;
        /**
         * Create Mode
         */
        if (!editMode) {
            dispatch(storeCuisine({ data, token })).then(() => {
                dispatch(getCuisines({ params: {} }));
                setModalShow(false);
                resetForm();
            })
        } else {
            /***
             * Edit Mode
             */
            dispatch(updateCuisine({ data: data, id: cuisineID, token: token })).then(() => {
                dispatch(getCuisines({ params: {} }));
                setModalShow(false);
                resetForm();
            })
        }
    }
    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyCuisine({ id, token }))
            }
        });
    }
    return (
        <MainLayout isSearchable={false}>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="slot-modal-vcenter"
                centered
                onHide={() => onHide()}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="slot-modal-vcenter" as="h5">
                        {editMode ? "Edit Restaurant Type" : "Create Restaurant Type"}
                    </Modal.Title>
                </Modal.Header>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={inputs}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        handleFormSubmit(values, resetForm)
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit} >
                            <Modal.Body>
                                <Row className="fieldset-row" >
                                    <Col md={12}>
                                        <Form.Label htmlFor='name' className="mb-0">Name</Form.Label>
                                        <Form.Control id="name" type='text' placeholder="eg. Chinese, Fast Food" name="name" value={values.name}
                                            onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                                        <Form.Control.Feedback type="invalid" className={!!errors.name ? "d-block" : ""}>
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className="border-0">
                                <Button type="submit" size="sm">{editMode ? "Update" : "Create"}</Button>
                            </Modal.Footer>
                        </Form>
                    )
                    }
                </Formik >
            </Modal>
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2 className="main-title mb-0">Restaurant Type</h2>
                        <Button variant="info" size="sm" onClick={(e) => onCreate()}>
                            Create Restaurant Type
                        </Button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns}>
                                <tbody>
                                    {cuisines && cuisines.map((cuisine: any) => {
                                        return (
                                            <tr key={cuisine?._id}>
                                                <td>
                                                    <h6>{cuisine?.name}</h6>
                                                </td>

                                                <td>
                                                    <b>
                                                        {formateDate(cuisine?.createdAt)}

                                                    </b>
                                                </td>
                                                <td>
                                                    {cuisine?.isPublished ? <span className="app-badge badge-active">Activated</span> : <span className="app-badge badge-trashed">Inactivated</span>}
                                                </td>
                                                <td style={{ width: "20%" }}>
                                                    <div className="d-flex gap-2">
                                                        <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={(e) => onEdit(cuisine?._id)}>
                                                            Edit
                                                        </Button>
                                                        <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(cuisine?._id)}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {(cuisines && cuisines.length !== 0) ? null : <NoDataFound colSpan={5} />}
                                </tbody>
                            </ThemeTable>
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default Cuisine;