


import { useEffect, useState, FormEvent } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/auth/useAuth";
import logo from "../../assets/img/svg/logo.svg"
import "./SignIn.css"
import { IUserWithAccessToken, logIn } from '../../store/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/hooks';
function SignIn() {
    const { setAuth, persist, setPersist } = useAuth();
    const { user, accessToken } = useAppSelector((state) => state.auth);
    const initialState = {
        email: '',
        password: '',
    }
    const [formInputs, setFormInputs] = useState(initialState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    /**
     * Redirect path 
     */
    const from = location.state?.from?.pathname || '/dashboard';
    console.log(user, accessToken);
    useEffect(() => {
        if (user !== undefined && accessToken !== undefined) {
            console.log("DDDD")
            navigate(from, { replace: true });
        }
    }, [])
    const signIn = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            dispatch(logIn(formInputs)).then((response: any) => {
                if (response.type === 'auth/login/fulfilled') {
                    console.log("Login Success")
                    const user: IUserWithAccessToken = response?.payload?.results;
                    const accessToken: string = user.accessToken;
                    setAuth(user, accessToken);
                    navigate(from, { replace: true });
                }
            })
        }
        catch (err) {
            console.log("Catch block")
        }
    }
    const togglePersist = (e: React.ChangeEvent<HTMLInputElement>) => {
        const persist: any = e.target.checked;
        setPersist(persist);
    }
    useEffect(() => {
        localStorage.setItem("persist", `${persist}`);
    }, [persist]);

    return (
        <main className="page-center">
            <article className="sign-in">
                <img src={logo} className="sign-in__logo" />
                <h1 className="sign-in__title">Welcome back!</h1>
                <p className="sign-in__subtitle">Sign in to your account to continue</p>
                <form className="sign-in-form form" onSubmit={signIn}>
                    <label className="form-label-wrapper">
                        <p className="form-label">Email</p>
                        <input className="form-input" type="email" placeholder="Enter your email" required={true} value={formInputs?.email} onChange={(e) => setFormInputs({ ...formInputs, email: e.target.value })} />
                    </label>
                    <label className="form-label-wrapper">
                        <p className="form-label">Password</p>
                        <input className="form-input" type="password" placeholder="Enter your password" required={true} value={formInputs?.password} onChange={(e) => setFormInputs({ ...formInputs, password: e.target.value })} />
                    </label>
                    <a className="link-info forget-link" href="##">Forgot your password?</a>
                    <label className="form-checkbox-wrapper">
                        <input className="form-checkbox" type="checkbox" checked={persist} onChange={togglePersist} />
                        <span className="form-checkbox-label">Remember me next time</span>
                    </label>
                    <button className="form-btn btn-primary-default" type="submit">Sign in</button>
                </form>
            </article>
        </main>
    )
}

export default SignIn;