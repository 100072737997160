import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import { Button, Dropdown } from "react-bootstrap";
import Paginator from "../../components/ui-components/pagination/Paginator";
import useTitle from "../../hooks/useTitle";
import { Link, useNavigate } from "react-router-dom";
import { destroyCluster, getClusters } from "../../store/cluster/clusterSlice";
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
function Clusters(props: Props) {
    useTitle('Pickup Clusters');
    const columns: Array<Column> = [
        { label: "Name", accessor: "cluster_name" },
        { label: "Cluster ID", accessor: "cluster_id" },
        { label: "Basic Address", accessor: "basic_address" },
        { label: "Actions", accessor: "actions" },
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const clusters = useAppSelector((state) => state.clusters.all);
    const { totalPages } = useAppSelector((state) => state.clusters);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    useEffect(() => {
        const params = { query: query, pageNumber: pageNumber };
        if (!token) return;
        dispatch(getClusters({ params, token }));
    }, [pageNumber, query]);

    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
    }
    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyCluster({ id, token }));
            }
        });
    }
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Name or Number ..." onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2 className="main-title mb-0">Pickup Clusters</h2>
                        <Link to="/clusters/create" className="btn btn-info btn-sm">Add Pickup Cluster</Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {
                                        clusters && clusters.map((cluster) => {
                                            return (
                                                <tr key={cluster?._id}>
                                                    <td >
                                                        <h5>{cluster?.name}</h5>
                                                    </td>
                                                    <td>
                                                        <b className="me-3">{cluster?.clusterID}</b>
                                                    </td>
                                                    <td>
                                                        {cluster?.address?.street}
                                                        <p className="mb-0"><b className="pe-3">City:</b>   {cluster?.address?.city} </p>
                                                        <p className="mb-0"><b className="pe-3">State:</b>   {cluster?.address?.state}</p>
                                                        <p className="mb-0"><b className="pe-3">Country:</b> {cluster?.address?.country}  - <b><span className="text-success" title="Postal Code">{cluster?.address?.zipCode}</span></b></p>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-2">
                                                            <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={() => navigate(`/clusters/${cluster?._id}`)}>
                                                                Edit
                                                            </Button>
                                                            <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(cluster?._id)} >
                                                                Delete
                                                            </Button>
                                                            <Dropdown>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    className="dropdown-btn transparent-btn remove-toggle" as={"button"}>
                                                                    <div className="sr-only">More info</div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                                        strokeLinecap="round" strokeLinejoin="round"
                                                                        className="feather feather-more-horizontal" aria-hidden="true">
                                                                        <circle cx="12" cy="12" r="1"></circle>
                                                                        <circle cx="19" cy="12" r="1"></circle>
                                                                        <circle cx="5" cy="12" r="1"></circle>
                                                                    </svg>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href={`/clusters/${cluster?._id}/restaurants`}>View Restaurants</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {(clusters && clusters.length !== 0) ? null : <NoDataFound colSpan={6} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default Clusters;