import axios, { AxiosResponse, ParamEncoder } from 'axios';

import { API_BASE_URL } from '../../config/constants';
axios.defaults.withCredentials = true;


export const getDriversAPI = async (params: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/drivers`,
    method: 'GET',
    params: params,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}
export const getDriverAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/drivers/${id}`,
    method: 'GET',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}


export const destroyDriverAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/drivers/${id}`,
    method: 'DELETE',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}


export const updateDriverAPI = async (data: Record<string, any>, id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/drivers/${id}`,
    data: data,
    method: 'PUT',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}


export const getTransactionsAPI = async (params: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/transactions/driver`,
    method: 'GET',
    params: params,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}
