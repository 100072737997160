import * as React from 'react';
import { Props } from "../../types";
import Table from "../../components/ui-components/table/Table";
import MainLayout from "../../components/layouts/MainLayout";
import { useAppDispatch, useAppSelector } from '../../hooks/redux/hooks';
import * as formik from 'formik';
import * as yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRestaurant, getRestaurants, verifyRestaurant } from '../../store/restaurant/restaurantSlice';
import { getTimeSlots } from '../../store/timeslot/timeSlotSlice';
import { getCuisines } from '../../store/cuisine/cuisineSlice';
import useTitle from '../../hooks/useTitle';
import { getClusters } from '../../store/cluster/clusterSlice';

function RestaurantDetails(props: Props) {
    useTitle("Edit Details");
    const { Formik } = formik;
    /**
     * RestaurantID in params
     */
    const { id } = useParams();
    const [initialValues, setInitialValues] = useState({
        restaurantName: '',
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        lat: 0,
        lng: 0,
        rating: 0,
        email: "",
        firstName: "",
        lastName: "",
        contactEmail: "",
        contactNumber: "",
        cuisines: [],
        slots: [],
        isVerified: false,
        isActivated: false,
        clusterID: {
            value: "",
            label: ""
        },
    })
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    let isVerified = [true];
    if (pathname?.match("/edit/")) {
        isVerified.push(false);
    }
    const validationSchema = yup.object().shape({
        restaurantName: yup.string().required("Restaurant name is a required field."),
        street: yup.string().required("Street is a required field."),
        city: yup.string().required("Street is a required field."),
        state: yup.string().required("State is a required field."),
        zipCode: yup.string().matches(/^[A-Za-z][0-9][A-Za-z]\s{0,1}[0-9][A-Za-z][0-9]$/, 'Invalid zipcode ').required("Zip is a required field."),
        country: yup.string().required("Country is a required field."),
        lat: yup.number(),
        lng: yup.number(),
        rating: yup.number().min(0, 'Rating cannot be less than 0')
            .max(5.0, 'Rating cannot be greater than 5')
            .required('Rating is required'),
        email: yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Invalid email address").required("Email is a required field."),
        firstName: yup.string().required("First name is a required field."),
        lastName: yup.string().required("Last name is a required field."),
        contactEmail: yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Invalid email address"),
        contactNumber: yup.string(),
        cuisines: yup.array().min(1, "Food Category must have at least 1 items."),
        slots: yup.array().min(1, "Slot Timing must have at least 1 items."),
        isVerified: yup.bool().oneOf(isVerified, 'This is a required field.'),
        isActivated: yup.bool().oneOf(isVerified, 'This is a required field.'),
        clusterID: yup.object().shape({
            label: yup.string().required("ClusterID is a required field."),
            value: yup.string().required("ClusterID is a required field.")
        })
    });
    const [slots, setSlots] = useState([]);
    const [cuisines, setCuisines] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [submitBtnName, setSubmitBtnName] = useState("");
    const { singleRestaurant } = useAppSelector((state) => state.restaurants);
    const timeSlotsState = useAppSelector((state) => state.timeSlots.all);
    const cuisinesState = useAppSelector((state) => state.cuisines.all);
    const clustersState = useAppSelector((state) => state.clusters.all);
    const token = useAppSelector((state) => state.auth.accessToken);



    useEffect(() => {
        const slotsData: any = timeSlotsState?.map((slot: any) => ({ ...slot, value: slot._id, label: slot.name }));
        const cuisinesData: any = cuisinesState?.map((cuisine: any) => ({ ...cuisine, value: cuisine._id, label: cuisine.name }));
        const clustersData: any = clustersState?.map((cluster: any) => ({ ...cluster, value: cluster._id, label: `${cluster.name} (${cluster.clusterID})` }));

        setSlots(slotsData);
        setCuisines(cuisinesData);
        setClusters(clustersData);
        if (singleRestaurant && slots && cuisines) {
            const selectedSlots = slots?.filter((slot: any) => singleRestaurant?.slots?.includes(slot._id));
            const selectedCuisines = cuisines?.filter((cuisine: any) => singleRestaurant?.cuisines?.includes(cuisine._id));
            const selectedCluster: any[] = clusters?.filter((cluster: any) => cluster?._id === singleRestaurant?.clusterID)
            setInitialValues({
                ...initialValues,
                restaurantName: singleRestaurant?.name,
                street: singleRestaurant?.address?.street,
                city: singleRestaurant?.address?.city,
                state: singleRestaurant?.address?.state,
                zipCode: singleRestaurant?.address?.zipCode,
                country: singleRestaurant?.address?.country,
                lat: singleRestaurant?.address?.lat ?? 0,
                lng: singleRestaurant?.address?.lng ?? 0,
                email: singleRestaurant?.email,
                firstName: singleRestaurant?.restaurantPersonDetails?.firstName,
                lastName: singleRestaurant?.restaurantPersonDetails?.lastName,
                contactEmail: singleRestaurant?.restaurantPersonDetails?.email,
                contactNumber: singleRestaurant?.restaurantPersonDetails?.phoneNumber,
                isVerified: singleRestaurant?.isVerified,
                isActivated: singleRestaurant?.isActivated,
                cuisines: selectedCuisines,
                slots: selectedSlots,
                clusterID: {
                    value: selectedCluster?.[0]?.value ?? "",
                    label: selectedCluster?.[0]?.label ?? ""
                },
                rating: singleRestaurant?.rating,

            });
        }
    }, [slots, cuisines, clusters]);


    useEffect(() => {
        dispatch(getTimeSlots({}));
        dispatch(getCuisines({ params: { query: "", pageNumber: 1, documentLimit: 1000 } }));
        if (!id) return;
        if (!token) return;
        dispatch(getClusters({ params: {}, token }))
        dispatch(getRestaurant({ id, token }));

        if (pathname?.match("/edit/")) {
            setSubmitBtnName("Update Restaurant");
        }
        if (pathname?.match("/verify/")) {
            setSubmitBtnName("Verify Restaurant");
        }
    }, [id, token, pathname, dispatch]);


    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!id) return;
        if (!token) return;
        const finalData: Object = {};
        Object.assign(finalData, {
            restaurantName: data?.restaurantName,
            street: data?.street,
            city: data?.city,
            state: data?.state,
            zipCode: data?.zipCode,
            country: data?.country,
            firstName: data?.firstName,
            lastName: data?.lastName,
            contactEmail: data?.contactEmail,
            contactNumber: data?.contactNumber,
            dialCode: data?.dialCode,
            isActivated: data?.isActivated,
            isVerified: data?.isVerified,
            clusterID: data?.clusterID?.value,
            cuisines: [data?.cuisines],
            lat: data?.lat,
            lng: data?.lng,
            rating: data?.rating
        })
        let cuisines: any[] = [];
        let slots: any[] = [];
        data?.cuisines?.map((item: any) => cuisines.push(item.value))
        data?.slots?.map((item: any) => slots.push(item.value))

        Object.assign(finalData, { slots, cuisines });
        dispatch(verifyRestaurant({ data: finalData, id, token })).then((data) => {
            navigate('/restaurants')
        });
    }

    return (
        <MainLayout isSearchable={false}>
            <main className="main users chart-page">
                <div className="container">
                    <h2 className="main-title">Restaurant Details</h2>
                    <Row className='justify-content-center'>
                        <Col md={8}>
                            <Formik
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                validateOnBlur={false}
                                validateOnChange={false}
                                onSubmit={(values, { resetForm }) => {
                                    handleFormSubmit(values, resetForm)
                                }}
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                                    <div className='registration form-container'>
                                        <Form noValidate onSubmit={handleSubmit} className='auth-form'>
                                            <Row className='justify-content-end'>
                                                <Col lg={6} md={8} sm={6} >
                                                </Col>
                                                <Col lg={6} md={8} sm={6} className='mb-2 text-end'>
                                                    <Button as="button" variant='primary' className='rounded-pill' type='submit'>
                                                        {submitBtnName}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <fieldset className='auth-fieldset'>
                                                <legend>Basic Details:</legend>
                                                <Row className="fieldset-row" >
                                                    <Col md={12}>
                                                        <Form.Label htmlFor='restaurantName'>Restaurant Name</Form.Label>
                                                        <Form.Control id="restaurantName" type='text' placeholder="eg. Tasty Trails" name="restaurantName" value={values.restaurantName}
                                                            onChange={handleChange} isValid={touched.restaurantName && !errors.restaurantName} isInvalid={!!errors.restaurantName} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.restaurantName}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Label htmlFor='slotTiming'>Slot Timing</Form.Label>
                                                        <Select
                                                            className="basic-single"
                                                            id='slotTiming'
                                                            classNamePrefix="select"
                                                            defaultValue={[]}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            name="slots"
                                                            isMulti={true}
                                                            options={slots}
                                                            value={values.slots}
                                                            onChange={(selectedOption) => setFieldValue("slots", selectedOption.map((option: any) => option))}
                                                        />
                                                        <Form.Control.Feedback type="invalid" className={!!errors.slots ? "d-block" : ""}>
                                                            {errors.slots}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Label htmlFor='category'>Food Category</Form.Label>
                                                        <Select
                                                            className="basic-single"
                                                            id='slotTiming'
                                                            classNamePrefix="select"
                                                            defaultValue={[]}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            name="cuisines"
                                                            isMulti={true}
                                                            options={cuisines}
                                                            value={values.cuisines}
                                                            onChange={(selectedOption) => setFieldValue("cuisines", selectedOption.map((option: any) => option))}
                                                        />
                                                        <Form.Control.Feedback type="invalid" className={!!errors.cuisines ? "d-block" : ""}>
                                                            {errors.cuisines}
                                                        </Form.Control.Feedback>
                                                    </Col>

                                                    <Col md={12} className='mb-2'>
                                                        <Form.Label htmlFor="street">Address</Form.Label>
                                                        <Form.Control id="street" placeholder="eg. 4102 43rd Avenue"
                                                            type='text' name="street" value={values.street}
                                                            onChange={handleChange} isValid={touched.street && !errors.street} isInvalid={!!errors.street} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.street}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={5} className='mb-2'>
                                                        <Form.Label htmlFor="city">City</Form.Label>
                                                        <Form.Control id="city" placeholder="eg. Ross River"
                                                            type='text' name="city" value={values.city}
                                                            onChange={handleChange} isValid={touched.city && !errors.city} isInvalid={!!errors.city} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.city}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={3} className='mb-2'>
                                                        <Form.Label htmlFor="state">State</Form.Label>
                                                        <Form.Control id="state" placeholder="eg. Yukon" type='text' name="state" value={values.state}
                                                            onChange={handleChange} isValid={touched.state && !errors.state} isInvalid={!!errors.state} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.state}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={4} className='mb-2'>
                                                        <Form.Label htmlFor="zipCode">Zip code</Form.Label>
                                                        <Form.Control id="zipCode" placeholder="eg. Y1A 1S0" type='text' name="zipCode" value={values.zipCode}
                                                            onChange={handleChange} isValid={touched.zipCode && !errors.zipCode} isInvalid={!!errors.zipCode} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.zipCode}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Label htmlFor="county">Country</Form.Label>
                                                        <Form.Control id="county" placeholder="eg. Canada" type='text' name="country" value={values.country}
                                                            onChange={handleChange} isValid={touched.country && !errors.country} isInvalid={!!errors.country} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.country}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <fieldset className='auth-fieldset'>
                                                <legend>Administrator Level Info:</legend>
                                                <Row className="fieldset-row">
                                                    <Col md={6}>
                                                        <Form.Label htmlFor='isActivated'>Active/Inactivate</Form.Label>
                                                        <Form.Check
                                                            type="switch"
                                                            id="isActivated"
                                                            label={values.isActivated ? "Active" : "Inactivate"}
                                                            checked={values.isActivated}
                                                            onChange={handleChange}
                                                        />
                                                        <Form.Control.Feedback type="invalid" className={!!errors.isActivated ? "d-block" : ""}>
                                                            {errors.isActivated}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Label htmlFor='isVerified'>Verified/Unverified</Form.Label>
                                                        <Form.Check
                                                            type="switch"
                                                            id="isVerified"
                                                            label={values.isVerified ? "Verified" : "Unverified"}
                                                            checked={values.isVerified}
                                                            onChange={handleChange}
                                                        />
                                                        <Form.Control.Feedback type="invalid" className={!!errors.isVerified ? "d-block" : ""}>
                                                            {errors.isVerified}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Label htmlFor='clusterID'>Assign Cluster</Form.Label>
                                                        <Select
                                                            className="basic-single"
                                                            id='slotTiming'
                                                            classNamePrefix="select"
                                                            defaultValue={[]}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            name="clusterID"
                                                            options={clusters}
                                                            onChange={(selectedOption: any) => setFieldValue("clusterID", selectedOption)}
                                                            value={values.clusterID}
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: !!errors.clusterID ? '#dc3545' : '#ced4da',
                                                                }),
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid" className={!!errors.clusterID?.value ? "d-block" : ""}>
                                                            {errors.clusterID?.value}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={6} className='mb-2'>
                                                        <Form.Label htmlFor="rating">Default Rating</Form.Label>
                                                        <Form.Control id="rating" type='text' name="rating" value={values.rating}
                                                            onChange={handleChange} isValid={touched.rating && !errors.rating} isInvalid={!!errors.rating} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.rating}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <hr />
                                            <fieldset className='auth-fieldset'>
                                                <legend>Login Details:</legend>
                                                <Row className="fieldset-row" >
                                                    <Col sm={6}>
                                                        <Form.Label htmlFor="email">Email Address</Form.Label>
                                                        <Form.Control id="email" placeholder="eg. tasty.trails@gmail.com" type='email' name="email" value={values.email}
                                                            onChange={handleChange} isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <hr />
                                            <fieldset className='auth-fieldset'>
                                                <legend>Contact Person Details:</legend>
                                                <Row className="fieldset-row">
                                                    <Col sm={6} className='mb-2'>
                                                        <Form.Label htmlFor="firstName">First Name</Form.Label>
                                                        <Form.Control id="firstName" placeholder="eg. John" name="firstName" value={values.firstName}
                                                            onChange={handleChange} isValid={touched.firstName && !errors.firstName} isInvalid={!!errors.firstName} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.firstName}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col sm={6} className='mb-2'>
                                                        <Form.Label htmlFor="lastName">Last Name</Form.Label>
                                                        <Form.Control id="lastName" placeholder="eg. Doe" name="lastName" value={values.lastName}
                                                            onChange={handleChange} isValid={touched.lastName && !errors.lastName} isInvalid={!!errors.lastName} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.lastName}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col sm={6} className='mb-2'>
                                                        <Form.Control id="contactEmail" placeholder="eg. john.doe@gmail.com" name="contactEmail" value={values.contactEmail}
                                                            onChange={handleChange} isValid={touched.contactEmail && !errors.contactEmail} isInvalid={!!errors.contactEmail} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.contactEmail}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col sm={6} className='mb-2'>
                                                        <Form.Control id="contactNumber" placeholder="eg. 867-969-0027" name="contactNumber" value={values.contactNumber}
                                                            onChange={handleChange} isValid={touched.contactNumber && !errors.contactNumber} isInvalid={!!errors.contactNumber} />
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                        </Form>
                                    </div>
                                )
                                }
                            </Formik >
                        </Col>
                    </Row>
                </div>
            </main>
        </MainLayout >
    )
}

export default RestaurantDetails;