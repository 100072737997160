import { useEffect, useState } from "react";

// import MainLayout from "../components/layouts/MainLayout";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { Button, Col, ProgressBar, Row, Tab, Tabs, Form, Card, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import edit_icon from '../assets/icons/edit_icon.svg';
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { idText } from "typescript";
import ReCAPTCHA from 'react-recaptcha';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import backIcon from '../../assets/img/landing/arrow-left.svg'
import { Link } from "react-router-dom";
import { error } from "console";
import { useNavigate } from 'react-router-dom';
import "./contactUs.css";
import { Helmet } from 'react-helmet';
import useTitle from "../../hooks/useTitle";
import facebook_icon from "../../assets/img/landing/facebook_icon.png";
import instagram_icon from "../../assets/img/landing/instagram_icon.png"
import linkedIn_icon from "../../assets/img/landing/linkedIn_icon.png"
import twitter_icon from "../../assets/img/landing/twitter_icon.png"
import mail_icon from "../../assets/img/landing/mail_icon.png"
import phone_icon from "../../assets/img/landing/phone_icon.png"
import location_icon from "../../assets/img/landing/location_icon.png";
import axios from "axios";
import { ADDRESS_MAP_LINK, API_BASE_URL, FB_LINK, INSTA_LINK } from "../../config/constants";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'

function ContactUs() {
  useTitle("ContactUs");
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [attachmentFiles, setAttachmentFiles] = useState<File | null>(null);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [mobileNum, setMobileNum] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [windowSize, setWindowSize] = useState<number>(window?.innerWidth);

  useEffect(() => {
    const handleSize = () => {
      setWindowSize(window?.innerWidth);
    };
    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize)
    }
  }, [])


  // Handler for reCAPTCHA verification
  const handleRecaptchaVerify = () => {
    setRecaptchaVerified(true);
  };
  useEffect(() => {
    console.log(recaptchaVerified)
  }, [recaptchaVerified])
  const initialInputs = {
    name: '',
    email: '',
    phoneNumber: '',
    whoAreYou: '',
    subject: '',
    message: '',
    orderNumber: '',
    preferredContMethod: '',
    location: '',
    attachment: '',
    newsletter: '',
  };

  const [inputs, setInputs] = useState(initialInputs);

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is a required field.'),
    email: yup.string().required('Email is a required field.'),
    whoAreYou: yup.string().required('This is a required field.'),
    subject: yup.string().required('This is a required field.'),
    preferredContMethod: yup.string().required('This is a required field.'),
    message: yup.string().required('This is a required field.'),
    location: yup.string().required('This is a required field.'),
  });
  const handleFormSubmit = async (formInput: any, resetForm: () => void) => {

    console.log(formInput);
    if (!recaptchaVerified) {
      Swal.fire({
        icon: 'error',
        text: 'Please complete captcha!',
      })
    }

    let newsletterSubscription = formInput.newsletter[0];
    console.log(newsletterSubscription)
    if (newsletterSubscription === "on") {
      newsletterSubscription = "true";
    } else {
      newsletterSubscription = "false"
    }
    // console.log(mobileNum)
    let role = formInput.whoAreYou;
    console.log(role)
    if (role === "green") {
      role = "driver"
    }
    else if (role === "blue") {
      role = "restaurant"
    }
    else if (role === "red") {
      role = "user"
    }
    let preferContact = formInput.preferredContMethod;
    if (preferContact === "green") {
      preferContact = "phone";
    }
    else if (preferContact === "red") {
      preferContact = "email"
    }
    let newNum = mobileNum.replace(dialCode, "")

    const formData = new FormData();
    formData.append('fullName', formInput.name);
    formData.append('email', formInput.email);
    formData.append('phoneNumber', newNum);
    formData.append('dialCode', dialCode);
    formData.append('role', role);
    formData.append('subject', formInput.subject);
    formData.append('message', formInput.message);
    formData.append('attachments', attachmentFiles as File);
    formData.append('orderNumber', formInput.orderNumber);
    formData.append('preferredContactMethod', preferContact);
    formData.append('newsletterSubscription', newsletterSubscription);



    await axios({
      method: "post",
      url: `${API_BASE_URL}/contact-support`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === "201") {
          Swal.fire('Your Form Has Submitted!', '', 'success').then(() => {
            navigate('/');
          })
        }



      })
      .catch((error) => {
        console.log(error);
        if (error) {
          Swal.fire({
            icon: 'error',
            text: error.message,
          })
        }
        // Swal.fire('Your Form Has Submitted!', '', 'success').then(() => {
        //   navigate('/');
        // })
      })
    resetForm();
    setInputs({
      name: "",
      email: "",
      phoneNumber: "",
      whoAreYou: "",
      subject: "",
      message: "",
      orderNumber: "",
      preferredContMethod: "",
      location: "",
      attachment: "",
      newsletter: "",

    });

  };


  let phoneNumberObject: any;
  const handlePhoneChange = (value: any) => {
    console.log('Phone number:', value);

    setMobileNum(value)
    if (value) {
      phoneNumberObject = parsePhoneNumberFromString(value);

      if (phoneNumberObject) {
        // Extract dial code and country code
        const newDialCode = `+${phoneNumberObject?.countryCallingCode}`;
        const countryCode = phoneNumberObject?.country;
        if (newDialCode !== undefined) {
          setDialCode(newDialCode)
        }

        console.log(mobileNum)
        console.log('Dial Code:', newDialCode);
        console.log('Country Code:', countryCode);
      }
    }
  };
  // useEffect(() => {
  //   let newNum = mobileNum.replace(dialCode, "")
  //   setMobileNum(newNum);
  // }, [])
  // const handleNumChange = (e: any) => {
  //   const newValue = e.target.value;

  //   // Use a regex to check if the newValue contains only numbers and spaces
  //   if (/^[0-9\s]+$/.test(newValue)) {
  //     // If it's a valid number, update the state
  //     setInputValue(newValue);
  //   } else {
  //     // If it's not a valid input, you can choose to clear the input
  //     setInputValue('');
  //   }


  // };


  return (
    <div>

      <section className="contact_section">
        <div className="back_button_div" style={{ cursor: "pointer" }} >
          <button className="left_arrow" >
            <Link to="/" className="nav-link">
              {/* <img src={backIcon} alt="back_icon" style={{ height: "40px", width: "40px" }} /> */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill={`${windowSize < 1200 ? '#eb6841' : `#f6f6f6`} `} d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
            </Link>
          </button>
        </div>
        <div className="container contact_form_main">
          <div className="contactInfo">
            <div>
              <h2>Contact Info</h2>
              <ul className="info p-0"  >

                <li>
                  <a href={ADDRESS_MAP_LINK} className="contact_info d-flex justify-content-between">
                    <span className="icon_span d-flex align-items-center justify-content-center">
                    <img src={location_icon} alt="Location" />
                  </span>
                  <span>

                    140 Yonge St, 224, Toronto,
                    <br />
                    Ontario, Canada,
                    <br />
                    M2R1X6

                  </span>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@freeyourkitchen.com" className="contact_info d-flex justify-content-between">
                    <span className="icon_span d-flex align-items-center justify-content-center">
                    <img src={mail_icon} alt="Email" />
                  </span>
                    <span className="d-flex align-items-center">
                      info@freeyourkitchen.com
                  </span>
                  </a>
                </li>

              </ul>
            </div>
            <ul className="sci">
              <li>
                <a href={FB_LINK}>
                  <img src={facebook_icon} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href={INSTA_LINK}>
                  <img src={instagram_icon} alt="Instagram" />
                </a>
              </li>

            </ul>
          </div>

          <div className="contactForm">
            <h2>Send a Message</h2>
            <Formik
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {

                handleFormSubmit(values, resetForm)
              }}
              enableReinitialize={true}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={inputs}

            >
              {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (

                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="fieldset-row" >

                    <Col xxl={3} lg={4} sm={6} className="mb-1">
                      <Form.Label style={{ marginBottom: "-5px" }} htmlFor="name">Full Name</Form.Label>
                      <Form.Control id="name" type='text' placeholder="Ron Calinan" name="name" className="inputs" value={values.name}
                        onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xxl={3} lg={4} sm={6} className="mb-1">
                      <Form.Label style={{ marginBottom: "-5px" }} htmlFor="email">Email</Form.Label>
                      <Form.Control id="email" type='email' className="inputs" placeholder="shanks@gmail.com" name="email" value={values.email}
                        onChange={handleChange} isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xxl={3} lg={4} sm={6} className="mb-1">
                      <Form.Label style={{ marginBottom: "-5px" }} htmlFor="number">Phone</Form.Label>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        value={mobileNum}
                        onChange={(value: any) => handlePhoneChange(value)}
                        className="newNumber"
                      />

                    </Col>
                    <Col xxl={3} lg={4} sm={6} className="mb-1">
                      <Form.Label style={{ marginBottom: "-4px" }} htmlFor="whoAreYou">Who are you?</Form.Label>
                      <Form.Select aria-label="who Are You" className="selct_form" name="whoAreYou" value={values.whoAreYou}
                        onChange={handleChange} isValid={touched.whoAreYou && !errors.whoAreYou} isInvalid={!!errors.whoAreYou}>
                        <option>Please choose...</option>
                        <option value="green">Driver</option>
                        <option value="red">Customer</option>
                        <option value="blue">Restaurant</option>

                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.whoAreYou}
                      </Form.Control.Feedback>
                    </Col>

                    <Col xxl={3} lg={4} sm={6} className="mb-1">
                      <Form.Label htmlFor="attachment" >Attachment</Form.Label>
                      <input
                        className="attachment_input"
                        type="file"
                        id="attachment"
                        name="attachment"
                        accept=".pdf, .doc, .docx" // Specify the allowed file types
                        onChange={(event: any) => {
                          console.log(event.target.files[0]);
                          setAttachmentFiles(event.target.files[0]);
                        }}
                      />
                    </Col>

                    <Col xxl={3} lg={4} sm={6} className="mb-1">
                      <Form.Label style={{ marginBottom: "-5px" }} htmlFor="location">Location</Form.Label>
                      <Form.Control id="location" type='text' className="inputs" placeholder="Royal Palace" name="location" value={values.location}
                        onChange={handleChange} isValid={touched.location && !errors.location} isInvalid={!!errors.location} />
                      <Form.Control.Feedback type="invalid">
                        {errors.location}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xxl={3} lg={4} sm={6} className="mb-1">
                      <Form.Label style={{ marginBottom: "-5px" }} htmlFor="orderNumber">Order Number (If Applicable)</Form.Label>
                      <Form.Control id="orderNumber" type='text' placeholder="#12345678" className="inputs" name="orderNumber" value={values.orderNumber}
                        onChange={handleChange} isValid={touched.orderNumber && !errors.orderNumber} isInvalid={!!errors.orderNumber} />
                      <Form.Control.Feedback type="invalid">
                        {errors.orderNumber}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xxl={3} lg={4} sm={6} className="mb-1">
                      <Form.Label style={{ marginBottom: "-4px" }} htmlFor="preferredContMethod">Preferred Contact Method</Form.Label>
                      <Form.Select aria-label="who Are You" className="selct_form" name="preferredContMethod" value={values.preferredContMethod}
                        onChange={handleChange} isValid={touched.preferredContMethod && !errors.preferredContMethod} isInvalid={!!errors.preferredContMethod}>
                        <option>Please choose...</option>
                        <option value="green">Phone</option>
                        <option value="red">Email</option>

                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.preferredContMethod}
                      </Form.Control.Feedback>
                    </Col>



                    <Col xxl={4} lg={4} sm={6} className="mb-1">
                      <Form.Label style={{ marginBottom: "-5px" }} htmlFor="subject"> Subject</Form.Label>
                      <Form.Control id="subject" type='text' className="inputs" placeholder="General Query" name="subject" value={values.subject}
                        onChange={handleChange} isValid={touched.subject && !errors.subject} isInvalid={!!errors.subject} />
                      <Form.Control.Feedback type="invalid">
                        {errors.subject}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xxl={8} lg={8} sm={6} className="mb-2">
                      <Form.Label style={{ marginBottom: "-5px" }} htmlFor="message">Message</Form.Label>
                      <Form.Control id="message" as="textarea" rows={1} placeholder="Your Message Goes Here." className="inputs" name="message" value={values.message} onChange={handleChange} isValid={touched.message && !errors.message} isInvalid={!!errors.message} />
                      <Form.Control.Feedback type="invalid">
                        {errors.message}
                      </Form.Control.Feedback>
                    </Col>

                    <Col xxl={3} className="mb-2 pt-6">
                      <Form.Check
                        type="checkbox"
                        id="newsletter"
                        name="newsletter"
                        label="Subscribe to Newsletter"

                        // checked={values.name}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.newsletter}
                      </Form.Control.Feedback>
                    </Col>
                    {/* <Col xxl={4} className="mb-3">

                    </Col> */}
                  </Row>
                  <Helmet>
                    <script
                      src="https://www.google.com/recaptcha/api.js"
                    />
                  </Helmet>
                  {/* <div className="g-recaptcha" id="rcaptcha"  data-si
                  ey="6LfWgnEoAAAAAOChLs2BGdA-w353uDLZGqbVQIkC"/> */}
                  <ReCAPTCHA
                    sitekey="6LfWgnEoAAAAAOChLs2BGdA-w353uDLZGqbVQIkC"
                    onChange={handleRecaptchaVerify}
                  />
                  <Button variant="app-warning" type="submit" className="ps-4 pe-4 submit_button mt-3">
                    Submit
                  </Button>
                </Form>
              )
              }
            </Formik >
          </div>

        </div>
      </section>
    </div>
  );
}


export default ContactUs;




