import "./MainLayout.css";
import "../../assets/css/Icons.css";
import Aside from "./Aside";
import Header from "./Header";
import Footer from "./Footer";
import { Props } from "../../types";
import { useAppSelector } from "../../hooks/redux/hooks";



interface MainLayoutSearchableProps extends Props {
    isSearchable: true;
    query: string;
    queryPlaceholder?: string;
    onSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface MainLayoutNonSearchableProps extends Props {
    isSearchable: false;
}

type MainLayoutProps = MainLayoutSearchableProps | MainLayoutNonSearchableProps;

function MainLayout(props: MainLayoutProps) {
    const { sidebarExpanded } = useAppSelector((state) => state.app);
    return (
        <div className="page-flex">
            <Aside sidebarExpanded={sidebarExpanded} />
            <div className="main-wrapper">
                <Header isSearchable={props.isSearchable} query={props.query} queryPlaceholder={props.queryPlaceholder} onSearchInputChange={props.onSearchInputChange} />
                {props.children}
                <Footer />
            </div>
        </div>
    )
}
export default MainLayout;