import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";


enum Position {
    TOP_LEFT = "top-left",
    TOP_RIGHT = "top-right",
    TOP_CENTER = "top-center",
    BOTTOM_LEFT = "bottom-left",
    BOTTOM_RIGHT = "bottom-right",
    BOTTOM_CENTER = "bottom-center",
}
enum Type {
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
    DEFAULT = "default",
}
enum Theme {
    LIGHT = "light",
    DARK = "dark",
}


interface ToastOption {
    position: Position,
    autoClose: 5000,
    hideProgressBar: Boolean,
    closeOnClick: Boolean,
    pauseOnHover: Boolean,
    draggable: Boolean,
    progress: undefined,
    theme: Theme,
    type: Type,
}

interface Toast {
    message: string;
    option: ToastOption;
}
// Define the initial state using that type
const initialState: Toast = {
    message: "",
    option: {
        position: Position.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: Theme.LIGHT,
        type: Type.SUCCESS
    }
}



export const toasterSlice = createSlice({
    name: "toaster",
    initialState: initialState,
    reducers: {
        successAlert: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.option.type = Type.SUCCESS;
        },
        warningAlert: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.option.type = Type.WARNING;
        },
        infoAlert: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.option.type = Type.INFO;
        },
        errorAlert: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.option.type = Type.ERROR;
        },
        clearAlert: (state) => {
            state.message = "";
            state.option.type = Type.SUCCESS;
        }
    },
});

export const { errorAlert, successAlert, warningAlert, infoAlert, clearAlert } = toasterSlice.actions;

export default toasterSlice.reducer;
