import "./JoinAsRestroPage.css";
import secondImg from "../../assets/img/landing/svg/margin_svg.svg"
import firstImg from "../../assets/img/landing/svg/minimal_svg.svg"
import thirdImg from "../../assets/img/landing/svg/customers_svg.svg"
import appStoreImg from "../../assets/img/svg/AppStore_black.svg";
import googleStoreImg from "../../assets/img/svg/GooglePlay_black.svg";
import "./Land.css";
import joinAsFrom from "../../assets/img/landing/join_as_restro_frame.webp";
import Landing from "./Landing";
import leftBlackArrow from "../../assets/img/landing/svg/left_arrow_black.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { CUSTOMER_APP_STORE_LINK, RESTAURANT_APP_STORE_LINK, RESTAURANT_PLAY_STORE_LINK, RESTRO_WEB_LOCATION } from "../../config/constants";
import rightIcon from "../../assets/img/landing/svg/right_side_icon.svg"
const JoinAsRestroPage = () => {
    const restorantSignUpUrl = `${RESTRO_WEB_LOCATION}/sign-up`;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="main_container1 ">
            <Link to={"/"}>
                <div className="left_arrow_back">
                    <img src={leftBlackArrow} alt="" />

                </div>
            </Link>

            <div className="first_under_main_container1 d-flex justify-content-center flex-column align-items-center">

                <div className="container top_bg_container d-flex flex-column gap-4 align-items-center">
                    <p>At Free Your Kitchen, we believe that access to delicious and wholesome meals should be convenient, affordable, and without unnecessary barriers. Our mission is to redefine the food delivery experience by providing a seamless and accessible service to our valued customers. We are committed to delivering more than just meals; we are delivering accessibility, affordability, and a sense of community.</p>
                    <p>You as our delivery driver will play a key role in our mission. Free Your Kitchen and our delivery drivers will work together to provide a seamless and accessible service to our valued customers</p>

                </div>
                <div className="d-flex justify-content-center pt-4">
                    <div className="gap-4 d-flex">
                        <a href={restorantSignUpUrl}  style={{ textDecoration: "none" }}>

                            <div className="join_as_restro_div3">
                                <div className="inside_join_as_restro_div3">
                                    Join as Restaurant
                                </div>

                            </div>



                        </a>

                    </div>

                </div>
            </div>


            <div className=" second_block">
                <div className="container first_under_arrow_second_block ">
                    <div className=" second_under_second_block d-flex justify-content-center">
                        <p>Free Your Kitchen provides a superior value to its restaurant partners in multiple ways which enables you  to get back in control of your delivery business.</p>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between mid_images_div">

                        <div className="d-flex flex-column col-12 col-md-3 image_blocks">
                            <div className="text_under_image_block">
                                <span>Drive sales by Increasing accessibility to customers</span>
                            </div>
                            <div className="d-flex justify-content-center align-items-center three_images">
                                <img src={thirdImg} alt="" />
                            </div>
                        </div>
                        <div className="d-flex flex-column col-12 col-md-3 image_blocks">
                            <div className="text_under_image_block">
                                <span>Increase profits with better margins vs. other delivery apps</span>
                            </div>
                            <div className="d-flex justify-content-center align-items-center three_images">
                                <img src={firstImg} alt="" style={{ width: "95%" }} />
                            </div>
                        </div>
                        <div className="d-flex flex-column col-12 col-md-3 image_blocks">
                            <div className="text_under_image_block">
                                <span>Minimal disruption to your dine-in customers</span>
                            </div>
                            <div className="d-flex justify-content-center align-items-center three_images">
                                <img src={secondImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            <div className="third_block">
                <div className="container d-flex justify-content-between flex-row under_third_block">
                    <div className="col-12 order-2 order-md-1 col-md-6 col-lg-5 gap-5 d-flex flex-column" style={{ margin: "auto" }}>
                        <div className="last_block_text">
                            <h1>
                                To become our restaurant partner, please download the app!
                            </h1>
                        </div>
                        <div className="d-flex justify-content-start gap-3 google_app_btns_div">
                            <a href={RESTAURANT_PLAY_STORE_LINK} style={{ textDecoration: "none" }}>
                            <div className="d-flex play_store_div_black gap-2" style={{ cursor: "pointer" }}>
                                <div className="d-flex justify-content-center align-items-center third_playstore_appstore">
                                    <img src={googleStoreImg} alt="Playstore" />
                                </div>
                                <div className="d-flex flex-column third_playstore_appstore_text">
                                    <span>Available on the</span>
                                    <span>Google Play</span>
                                </div>

                            </div>
                            </a>
                            <a href={RESTAURANT_APP_STORE_LINK} style={{ textDecoration: "none" }}>
                            <div className="d-flex app_store_div_black gap-2" style={{ cursor: "pointer" }} >
                                <div className="d-flex justify-content-center align-items-center third_playstore_appstore">
                                    <img src={appStoreImg} alt="AppStore" />
                                </div>
                                <div className="d-flex flex-column third_playstore_appstore_text">
                                    <span>Available on the</span>
                                    <span>App Store</span>
                                </div>
                            </div>
                            </a>
                        </div>

                    </div>
                    <div className="col-12 order-1 order-md-2 col-md-6 d-flex justify-content-center align-items-center lower_frane">
                        <img src={joinAsFrom} alt="" />
                    </div>
                </div>

            </div>
            <Landing />

        </div>
    )
}

export default JoinAsRestroPage;