import "./JoinAsRestroPage.css";
import secondImg from "../../assets/img/landing/svg/working_hours_svg.svg"
import firstImg from "../../assets/img/landing/svg/income_svg.svg"
import thirdImg from "../../assets/img/landing/svg/flexed_delivery_svg.svg"
import appStoreImg from "../../assets/img/svg/AppStore_black.svg";
import googleStoreImg from "../../assets/img/svg/GooglePlay_black.svg";
import "./Land.css";
import becomeDriverFrame from "../../assets/img/landing/becme_a_driver_frame.webp";
import Landing from "./Landing";
import leftBlackArrow from "../../assets/img/landing/svg/left_arrow_black.svg";
import { Link } from "react-router-dom";
import { DRIVER_APP_STORE_LINK, DRIVER_PLAY_STORE_LINK } from "../../config/constants";

const BecomeADriverPage = () => {

    return (
        <div className="main_container1 ">
            <Link to={"/"} >
                <div className="left_arrow_back">
                    <img src={leftBlackArrow} alt="" />
                </div>
            </Link>
            <div className="first_under_main_container2 d-flex justify-content-center gap-md-3 flex-column align-items-center">

                <div className="container top_bg_container d-flex flex-column gap-4 align-center align-items-center">
                    <p>At Free Your Kitchen, we believe that access to delicious and wholesome meals should be convenient, affordable, and without unnecessary barriers. Our mission is to redefine the food delivery experience by providing a seamless and accessible service to our valued customers. We are committed to delivering more than just meals; we are delivering accessibility, affordability, and a sense of community.</p>
                    <p>You as our delivery driver will play a key role in our mission. Free Your Kitchen and our delivery drivers will work together to provide a seamless and accessible service to our valued customers</p>

                </div>
                <div className="d-flex justify-content-center pt-4">
                    <div className="gap-4 d-flex">
                        <a href={DRIVER_PLAY_STORE_LINK} style={{ textDecoration: "none" }}>
                            <div className="d-flex play_store_div gap-2 " style={{ cursor: "pointer" }}>
                                <div className="d-flex justify-content-center align-items-center first_playstore_appstore">
                                    <img src={googleStoreImg} alt="Playstore" />
                                </div>
                                <div className="d-flex flex-column first_playstore_appstore_text">
                                    <span>Available on the</span>
                                    <span>Google Play</span>
                                </div>
                            </div>

                        </a>
                        <a href={DRIVER_APP_STORE_LINK} style={{ textDecoration: "none" }}>
                            <div className="d-flex app_store_div gap-2" style={{ cursor: "pointer" }}>
                                <div className="d-flex justify-content-center align-items-center first_playstore_appstore">
                                    <img src={appStoreImg} alt="AppStore" />
                                </div>
                                <div className="d-flex flex-column first_playstore_appstore_text">
                                    <span>Available on the</span>
                                    <span>App Store</span>
                                </div>
                            </div>
                        </a>

                    </div>

                </div>
            </div>

            <div className=" second_block">
                <div className="container first_under_arrow_second_block ">
                    <div className=" second_under_second_block d-flex justify-content-center">
                        <p>Free your kitchen provides a superior opportunity to its delivery drivers in multiple ways which enables you to get more value of your time.</p>
                    </div>
                    <div className="d-flex justify-content-between mid_images_div">

                        <div className="d-flex flex-column col-md-3 image_blocks ">
                            <div className="text_under_image_block">
                                <span>Earn a strong income with a steady cashflow</span>
                            </div>
                            <div className="d-flex image_blocks_images justify-content-center align-items-center">
                                <img src={firstImg} alt="" />
                            </div>
                        </div>
                        <div className="d-flex flex-column col-md-3 image_blocks">
                            <div className="text_under_image_block">
                                <span>Enjoy stable, and steady working hours</span>
                            </div>
                            <div className="d-flex image_blocks_images justify-content-center align-items-center">
                                <img src={secondImg} alt="" style={{ width: "95%" }} />
                            </div>
                        </div>
                        <div className="d-flex flex-column col-md-3 image_blocks">
                            <div className="text_under_image_block">
                                <span>Drive In within your familiar and fixed delivery routes</span>
                            </div>
                            <div className="d-flex image_blocks_images justify-content-center align-items-center">
                                <img src={thirdImg} alt="" style={{ width: "95%" }} />
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            <div className="third_block">
                <div className="container d-flex justify-content-between under_third_block ">
                    <div className="col-md-5 gap-5 d-flex flex-column col-sm-5 pt-sm-3" style={{ margin: "auto" }}>
                        <div className="last_block_text">
                            <h1>
                                To become our restaurant partner, please download the app!
                            </h1>
                        </div>
                        <div className="d-flex justify-content-start gap-3 google_app_btns_div">
                            <a href={DRIVER_PLAY_STORE_LINK} style={{ textDecoration: "none" }}>
                            <div className="d-flex play_store_div_black gap-2" style={{ cursor: "pointer" }}>
                                <div className="d-flex justify-content-center align-items-center third_playstore_appstore">
                                    <img src={googleStoreImg} alt="Playstore" />
                                </div>
                                <div className="d-flex flex-column third_playstore_appstore_text">
                                    <span>Available on the</span>
                                    <span>Google Play</span>
                                </div>

                            </div>
                            </a>
                            <a href={DRIVER_APP_STORE_LINK} style={{ textDecoration: "none" }}>
                            <div className="d-flex app_store_div_black gap-2" style={{ cursor: "pointer" }} >
                                <div className="d-flex justify-content-center align-items-center third_playstore_appstore">
                                    <img src={appStoreImg} alt="AppStore" />
                                </div>
                                <div className="d-flex flex-column third_playstore_appstore_text">
                                    <span>Available on the</span>
                                    <span>App Store</span>
                                </div>
                            </div>
                            </a>
                        </div>

                    </div>
                    <div className="col-12 order-1 col-md-6 col-sm-5 d-flex justify-content-center align-items-center lower_frane">
                        <img src={becomeDriverFrame} alt="" />
                    </div>
                </div>

            </div>
            <Landing />

        </div>
    )
}

export default BecomeADriverPage;