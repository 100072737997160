import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../config/constants';
axios.defaults.withCredentials = true;

export const getInstructionsAPI = async (params: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/instructions`,
    method: 'GET',
    params: params,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

export const storeInstructionAPI = async (data: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/instructions`,
    method: 'POST',
    data: data,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

export const destroyInstructionAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/instructions/${id}`,
    method: 'DELETE',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

export const updateInstructionAPI = async (data: Record<string, any>, id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/instructions/${id}`,
    data: data,
    method: 'PUT',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}