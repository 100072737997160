import axios, { AxiosResponse } from 'axios';
import { ADMIN_ENDPOINT } from '../../config/constants';
axios.defaults.withCredentials = true;
export const getDashboardDataAPI = async (token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${ADMIN_ENDPOINT}/dashboard`,
    method: 'GET',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

