import axios, { AxiosResponse } from 'axios';
import { ADMIN_ENDPOINT } from '../../config/constants';
axios.defaults.withCredentials = true;
export const loginAPI = async (credentials: { email: string, password: string }): Promise<AxiosResponse> => {
  const response = await axios.post(`${ADMIN_ENDPOINT}/auth/login`, credentials);
  return response.data;
}

export const logoutAPI = async (): Promise<AxiosResponse> => {
  const response = await axios.post(`${ADMIN_ENDPOINT}/auth/logout`);
  return response.data;
}

export const refreshTokenAPI = async (): Promise<AxiosResponse> => {
  const response = await axios.post(`${ADMIN_ENDPOINT}/auth/refresh-token`);
  return response.data;
}