
import "./StateCards.css";
import React from "react";



const StateCards: React.FC<{ title: string, count: number, icon: any, iconColor: any, stateIcons: any, statePercentage: any, monthName: string }> = ({ title, count, iconColor, icon, stateIcons, statePercentage, monthName }) => {
    return (
        <>

            <div className="col-md-6 col-xl-3 mb-3" >
                <article className="stat-cards-item">
                    <div className={iconColor ? iconColor + " stat-cards-icon" : "stat-cards-icon warning"}>
                        {icon}
                    </div>
                    <div className="stat-cards-info">
                        <p className="stat-cards-info__num">{count}</p>
                        <p className="stat-cards-info__title">{title}</p>
                        {/* <p className="stat-cards-info__progress">
                            <span className="stat-cards-info__profit success">
                                {stateIcons}
                                {statePercentage}
                            </span>
                            {monthName}
                        </p> */}
                    </div>
                </article>
            </div>
        </>
    )

}

export default StateCards;