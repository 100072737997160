import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from 'axios';
import { startLoading } from "../loading/loadingSlice";
import { getDashboardDataAPI } from "./authApi";
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";
interface DashboardData {
  users: number,
  foodItem: number,
  restaurants: number,
  orders: number
}

export interface IAppSate {
  sidebarExpanded: Boolean;
  dashboardData: DashboardData,
}

const initialState: IAppSate = {
  sidebarExpanded: true,
  dashboardData: {
    users: 0,
    foodItem: 0,
    restaurants: 0,
    orders: 0
  }
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setSidebar: (state, action: PayloadAction<Boolean>) => {
      state.sidebarExpanded = action.payload;
    },
    setDashboardData: (state, action: PayloadAction<any>) => {
      state.dashboardData = action.payload;
    },
  },
});
export const getDashboardData = createAsyncThunk<AxiosResponse, { token: string }, { rejectValue: any }>(
  'auth/login',
  async ({ token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getDashboardDataAPI(token);
      if (response.status) {
        /**
         * Success Login
         */
        thunkAPI.dispatch(setDashboardData(response?.results))
        return response;
      } else {
        thunkAPI.dispatch(errorAlert(response.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);



export const { setSidebar, setDashboardData } = appSlice.actions;

export default appSlice.reducer;
