import "./DeliveryZonesPage.css";
import { useEffect } from "react";
import leftBlackArrow from "../../assets/img/landing/svg/left_arrow_black.svg";
import { Link } from "react-router-dom";
const DeliveryZonesPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className=" main_container">

            <div className="container gap-md-3 gap-lg-4 gap-xl-5 d-flex flex-column first_under_main_container ">

                <div className="main_title">
                    <h1>Plan ahead and save money: How it works</h1>
                </div>
                <div className="paragraph_below_title d-flex justify-content-center align-center">
                    <p>At Free Your Kitchen, we're committed to not only bringing your fovourite restaurant flavours to your doorsteps, but also saving you money in the process. Our innovative approach to delivery scheduling is designed to optimize efficiency and reduce costs for our customers</p>
                </div>
                <div className="container d-flex flex-column gap-5 arrow_container">
                    <div className="first_under_arrow_container d-flex justify-content-between">
                        <span>Order Cut off Time.</span>
                        <span>Latest Delivery Time</span>
                    </div>
                    <div className="d-flex justify-content-between second_under_arrow_container">
                        <div className=" pointer d-flex justify-content-center align-items-center first_point_div">
                            <div className="text_content">
                                <p>Order by 5 PM</p>
                            </div>
                        </div>
                        <div className="d-flex gap-4 mid_arrow_div">
                            <div className=" pointer d-flex justify-content-center align-items-center">
                                <div className="text_content">

                                </div>
                            </div>
                            <div className=" pointer d-flex justify-content-center align-items-center">
                                <div className="text_content">

                                </div>
                            </div>
                        </div>
                        <div className=" pointer d-flex justify-content-center align-items-center ">
                            <div className="text_content">
                                <p>7:30 PM</p>
                            </div>
                        </div>
                    </div>
                    <div className="third_under_arrow_container d-flex justify-content-center">
                        <span>More order windows coming soon...</span>
                    </div>
                </div>
                <div className="below_arrow_container container gap-lg-3 gap-xl-5 d-flex flex-column">

                    <p>By streamlining our delivery process in this way, we're able to pass the savings on to you. Our vast delivery network covers a wide geographic area, allowing us to serve more customers with fewer delivery vehicles and less fuel consumption. This not only reduces our operating costs but also helps to minimize our environmental impact.</p>

                    <p>In addition, our strategic clustering of orders helps to reduce the likelihood of delivery delays, ensuring that your food arrives fresh and on time. This means fewer instances of food waste and a better overall experience for you, our valued customer.</p>


                </div>
                <Link to={"/"}>
                    <div className="left_arrow_back">
                        <img src={leftBlackArrow} alt="" />
                    </div>
                </Link>
            </div>


        </div>
    )
}


export default DeliveryZonesPage;