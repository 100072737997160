import useAuth from './useAuth';
import { refreshTokenAPI } from "../../store/auth/authApi";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { updateAccessToken } from '../../store/auth/authSlice';
const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const { user } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const refresh = async () => {
        const response: any = await refreshTokenAPI();
        const accessToken: any = response?.results?.accessToken;
        dispatch(updateAccessToken(accessToken));
        setAuth(user, accessToken)
        return accessToken;
    }
    return refresh;
}



export default useRefreshToken;