import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import ThemeTable, { Column } from "../../components/ui-components/table/ThemeTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { useEffect, useState } from "react";
import { destroyTimeSlot, getTimeSlots, storeTimeSlot, updateTimeSlot } from "../../store/timeslot/timeSlotSlice"
import { NoDataFound } from "../../components/ui-components/table/ThemeTable";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
function Slots(props: Props) {
    const columns: Array<Column> = [
        { label: "Name", accessor: "name" },
        { label: "Timing (Start - End)", accessor: "timing" },
        { label: "Delivery Timing", accessor: "delivery" },
        { label: "Status", accessor: "status" },
        { label: "Actions", accessor: "actions" },
    ];

    const initialValues = {
        name: '',
        startTime: "",
        endTime: "",
        deliveryTime: "",
        isPublished: false,
    }

    const [inputs, setInputs] = useState(initialValues)
    const { Formik } = formik;

    const validationSchema = yup.object().shape({
        name: yup.string().required("Slot name is a required field."),
        startTime: yup.string().required("Start Time is a required field."),
        endTime: yup.string().required("End Time is a required field."),
        deliveryTime: yup.string().required("Delivery Time is a required field."),
        // isPublished: yup.bool().oneOf([true], 'Field must be checked'),
    });

    const dispatch = useAppDispatch();
    const timeSlots = useAppSelector((state) => state.timeSlots.all);
    const token = useAppSelector((state) => state.auth.accessToken);
    const [modalShow, setModalShow] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [slotID, setSlotID] = useState("");
    useEffect(() => {
        dispatch(getTimeSlots({}));
    }, []);

    useEffect(() => {

    }, [slotID])
    const onEdit = (id: string) => {
        setSlotID(id);
        setModalShow(true);
        setEditMode(true);
        const singleTimeSlot = timeSlots.find(item => item._id === id);
        setInputs({
            ...inputs,
            name: singleTimeSlot?.name,
            endTime: singleTimeSlot?.endTime,
            startTime: singleTimeSlot?.startTime,
            deliveryTime: singleTimeSlot?.deliveryTime,
            isPublished: singleTimeSlot?.isPublished
        });
    }

    const onCreate = () => {
        setInputs(initialValues);
        setEditMode(false);
        setModalShow(true);
    }
    const onHide = () => {
        setModalShow(false);
        setEditMode(false);
        setInputs(initialValues);
    }
    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!token) return;
        /**
         * Create Mode
         */
        if (!editMode) {
            dispatch(storeTimeSlot({ data, token })).then(() => {
                console.log()
                dispatch(getTimeSlots({}));
                setModalShow(false);
                resetForm();
            })
        } else {
            /***
             * Edit Mode
             */
            dispatch(updateTimeSlot({ data: data, id: slotID, token: token })).then(() => {
                console.log()
                dispatch(getTimeSlots({}));
                setModalShow(false);
                resetForm();
            })
        }
    }
    const onDelete = (id: string) => {
        if (!token) return;
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyTimeSlot({ id, token }))
            }
        });
    }
    return (
        <MainLayout isSearchable={false}>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="slot-modal-vcenter"
                centered
                onHide={() => onHide()}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="slot-modal-vcenter" as="h5">
                        {editMode ? "Edit Slot" : "Create Slot"}
                    </Modal.Title>
                </Modal.Header>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={inputs}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        handleFormSubmit(values, resetForm)
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit} >
                            <Modal.Body>
                                <Row className="fieldset-row" >
                                    <Col md={12}>
                                        <Form.Label htmlFor='name' className="mb-0">Slot Name</Form.Label>
                                        <Form.Control id="name" type='text' placeholder="eg. Tasty Trails" name="name" value={values.name}
                                            onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                                        <Form.Control.Feedback type="invalid" className={!!errors.name ? "d-block" : ""}>
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Label htmlFor='startTime' className="mb-0">Start Time</Form.Label>
                                        <Form.Control id="startTime" type='time' name="startTime" value={values.startTime}
                                            onChange={handleChange} isValid={touched.startTime && !errors.startTime} isInvalid={!!errors.startTime} />
                                        <Form.Control.Feedback type="invalid" className={!!errors.startTime ? "d-block" : ""}>
                                            {errors.startTime}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Label htmlFor='endTime' className="mb-0">End Time</Form.Label>
                                        <Form.Control id="endTime" type='time' name="endTime" value={values.endTime}
                                            onChange={handleChange} isValid={touched.endTime && !errors.endTime} isInvalid={!!errors.endTime} />
                                        <Form.Control.Feedback type="invalid" className={!!errors.endTime ? "d-block" : ""}>
                                            {errors.endTime}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Label htmlFor='deliveryTime' className="mb-0">Delivery Time</Form.Label>
                                        <Form.Control id="deliveryTime" type='time' name="deliveryTime" value={values.deliveryTime}
                                            onChange={handleChange} isValid={touched.deliveryTime && !errors.deliveryTime} isInvalid={!!errors.deliveryTime} />
                                        <Form.Control.Feedback type="invalid" className={!!errors.deliveryTime ? "d-block" : ""}>
                                            {errors.deliveryTime}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className="border-0">
                                <Button type="submit" size="sm">{editMode ? "Update" : "Create"}</Button>
                            </Modal.Footer>
                        </Form>
                    )
                    }
                </Formik >
            </Modal>
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2 className="main-title mb-0">Time Slots</h2>
                        <Button variant="info" size="sm" onClick={(e) => onCreate()}>
                            Create Slot
                        </Button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns}>
                                <tbody>
                                    {timeSlots && timeSlots.map((timeSlot: any) => {
                                        return (
                                            <tr key={timeSlot?._id}>
                                                <td>
                                                    <h6>{timeSlot?.name}</h6>
                                                    <p className="m-0">
                                                        <b>{timeSlot?.startTime}</b>
                                                        <span className="me-1 ms-1">-</span>
                                                        <b>{timeSlot?.endTime}</b>
                                                    </p>
                                                </td>
                                                <td>
                                                    <b>{timeSlot?.startTime}</b>
                                                    <span className="me-1 ms-1">-</span>
                                                    <b>{timeSlot?.endTime}</b>
                                                </td>
                                                <td>
                                                    <b>{timeSlot?.deliveryTime}</b>
                                                </td>
                                                <td>
                                                    {timeSlot?.isPublished ? <span className="app-badge badge-active">Activated</span> : <span className="app-badge badge-trashed">Inactivated</span>}
                                                </td>
                                                <td style={{ width: "20%" }}>
                                                    <div className="d-flex gap-2">
                                                        <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={(e) => onEdit(timeSlot?._id)}>
                                                            Edit
                                                        </Button>
                                                        <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(timeSlot?._id)}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {(timeSlots && timeSlots.length !== 0) ? null : <NoDataFound colSpan={5} />}
                                </tbody>
                            </ThemeTable>
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default Slots;