import { Props } from "../../types";
import Table from "../../components/ui-components/table/Table";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { destroyUser, getUsers } from "../../store/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import defaultPNG from "../../assets/img/avatar/avatar-illustrated-02.png";
import defaultWEBP from "../../assets/img/avatar/avatar-illustrated-02.webp";
import { Button, Dropdown } from "react-bootstrap";
import Paginator from "../../components/ui-components/pagination/Paginator";
import useTitle from "../../hooks/useTitle";
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import { formateDate } from "../../helper/basic";
function Users(props: Props) {
    useTitle('Customers');

    const columns: Array<Column> = [
        { label: "Person Details", accessor: "profile_pic" },
        { label: "Cart", accessor: "cart" },
        { label: "UpdatedOn", accessor: "updatedAt" },
        { label: "CreatedOn", accessor: "createdAt" },
        { label: "Status", accessor: "status" },
        { label: "Actions", accessor: "actions" },
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const users = useAppSelector((state) => state.users.all);
    const { totalPages } = useAppSelector((state) => state.users);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");


    useEffect(() => {
        const params = { query: query, pageNumber: pageNumber };
        if (!token) return;
        dispatch(getUsers({ params, token }));
    }, [pageNumber, query]);

    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
    }

    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyUser({ id, token }));
            }
        });
    }
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Name or Number ..." onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <h2 className="main-title">Customers</h2>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {
                                        users && users.map((user) => {
                                            let profilePic = user?.profilePic ? user?.profilePic : undefined;
                                            profilePic = typeof profilePic === 'undefined' || profilePic === null || profilePic === "" ? undefined : profilePic;
                                            return (
                                                <tr key={user?._id}>
                                                    {/* <td width={"8%"}>
                                                        <div className="pages-table-img">
                                                            <picture>
                                                                <source srcSet={profilePic ? profilePic : defaultWEBP} type="image/webp" className="avatar-sm" />
                                                                <img src={profilePic ? profilePic : defaultPNG} alt="User avatar" className="avatar-sm" />
                                                            </picture>
                                                        </div>
                                                    </td> */}
                                                    <td>
                                                        <b>{user?.firstName} {user?.lastName}</b><br />
                                                        {user?.email ? <>{user?.email}<br /> </> : null}
                                                        {user?.dialCode}-{user?.phoneNumber}<br />
                                                    </td>
                                                    <td>
                                                        {user?.restaurantName ? <>{user?.restaurantName}<br /> </> : null}
                                                        {user?.totalCartValue ? <>Cart total ${Math.floor(user?.totalCartValue || 0)}<br /> </> : null}
                                                        {user?.cartDate ? <>{formateDate(user?.cartDate)}<br /> </> : null}
                                                    </td>
                                                    <td>
                                                        {formateDate(user?.updatedAt)}
                                                    </td>
                                                    <td>
                                                        {formateDate(user?.createdAt)}
                                                    </td>
                                                    {/* <td>
                                                        {user?.gender}
                                                    </td> */}
                                                    {/* <td>
                                                        {user?.birthday === "0000-00-00" ? null : user?.birthday}
                                                    </td> */}
                                                    <td>
                                                        {user?.isActivated ? <span className="app-badge badge-active">Activated</span> : <span className="app-badge badge-trashed">Inactivated</span>}
                                                        {user?.isVerified ? <span className="app-badge badge-active">Verified</span> : <span className="app-badge badge-trashed">Unverified</span>}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-2">
                                                            <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={(e) => navigate(`/users/${user?._id}`)}>
                                                                Edit
                                                            </Button>
                                                            <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(user?._id)} >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {(users && users.length !== 0) ? null : <NoDataFound colSpan={6} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default Users;