import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../../hooks/auth/useRefreshToken";
import useAuth from "../../hooks/auth/useAuth";
import { useAppDispatch } from "../../hooks/redux/hooks";
import { logOut } from "../../store/auth/authSlice";
import Loading from "../ui-components/Loading";
const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();
    const dispatch = useAppDispatch();
    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                console.error(err);
                dispatch(logOut({}));
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }
        // persist added here AFTER tutorial video
        // Avoids unwanted call to verifyRefreshToken
        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);
        return () => {
            isMounted = false;
        };
    }, [])

    useEffect(() => {
        // console.log(`isLoading: ${isLoading}`)
        // console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    }, [isLoading])

    return (
        <>
            {!persist
                ? <Outlet />
                : isLoading
                    ? <Loading />
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin