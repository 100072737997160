import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { loginAPI, logoutAPI } from './authApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";
export interface IUser {
  _id: string,
  name: string,
  email: string,
}
export interface IUserWithAccessToken extends IUser {
  refreshToken: string,
  accessToken: string,
}


export interface IAuthState {
  user: IUser | undefined;
  accessToken: string | undefined;
}

const initialState: IAuthState = {
  user: undefined,
  accessToken: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action: PayloadAction<IUserWithAccessToken>) => {
      const { refreshToken, accessToken, ...restUserData } = action.payload;
      state.user = restUserData;
      state.accessToken = accessToken;
    },
    logout: (state) => {
      state.user = undefined;
      state.accessToken = undefined;
    },
    updateAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    }
  },
});


export const logIn = createAsyncThunk<AxiosResponse, { email: string, password: string }, { rejectValue: string }>(
  'auth/login',
  async ({ email, password }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await loginAPI({ email, password });
      if (response.status) {
        /**
         * Success Login
         */
        thunkAPI.dispatch(login(response?.results))
        return response;
      } else {
        thunkAPI.dispatch(errorAlert(response.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const logOut = createAsyncThunk<AxiosResponse, any, { rejectValue: string }>('auth/logout', async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(startLoading());
    const response: any = await logoutAPI();
    if (response.status) {
      thunkAPI.dispatch(logout());
      return response;
    }
    return thunkAPI.rejectWithValue(response.message);
  } catch (error: any) {
    const err = error as AxiosError;
    if (error.response.status === 401) {
      thunkAPI.dispatch(logout());
    }
    console.log(error.response.status)
    thunkAPI.dispatch(errorAlert(err.message))
    return thunkAPI.rejectWithValue(err.message);
  }
  finally {
    thunkAPI.dispatch(completeLoading())
  }
});

export const { login, logout, updateAccessToken } = authSlice.actions;

export default authSlice.reducer;
