import { Routes, Route, BrowserRouter } from "react-router-dom"
import PageRoutes, { DashboardRoutes } from "./routes";
import PersistLogin from "./components/auth/PersistLogin";
import RequireAuth from "./components/auth/RequireAuth";
import useAuth from "./hooks/auth/useAuth";
import { Props } from "./types";
import { useEffect } from "react";
import NotFound404 from "./pages/errors/NotFound404";
function Router() {
    const { auth } = useAuth();
    useEffect(() => {
    }, [auth]);
    return (
        <BrowserRouter>
            <Routes>
                {/* Public Routes */}
                {PageRoutes && PageRoutes.map((page_route, index) => {
                    const Component = page_route.element;
                    return (<Route path={page_route.path} element={<Component />} key={index} />)
                })}
                {/* Protected Routes */}
                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth />}>
                        {DashboardRoutes && DashboardRoutes.map((page_route, index) => {
                            const Component: React.FC<Props> = page_route.element;
                            return (<Route path={page_route.path} element={<Component auth={auth} />} key={index} />)
                        })}
                    </Route>
                </Route>
                <Route path="*" element={<NotFound404 />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;