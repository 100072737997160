
import "./Header.css";
import avatar_png from "./../../assets/img/avatar/avatar-illustrated-02.png";
import avatar_webp from "./../../assets/img/avatar/avatar-illustrated-02.webp";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../hooks/redux/hooks";
import { logOut } from "../../store/auth/authSlice";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import { setSidebar } from "../../store/app/authSlice";


/**
 * Header Props 
 * Like Search Bar Props
 */

export interface HeaderProps {
    isSearchable: Boolean;
    query: string;
    queryPlaceholder?: string | undefined;
    onSearchInputChange: React.ChangeEventHandler<HTMLInputElement>;
}


function Header(props: HeaderProps) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const switchDarkMode = () => {
        if (document.body.classList.contains("darkmode")) {
            document.body.classList.remove("darkmode")
        } else {
            document.body.classList.add("darkmode")
        }
    }
    const dropdownRef: any = useRef();
    const [dropdown, setDropdown] = useState(true);
    const closeDropdown = (e: any) => {
        if (dropdownRef.current && dropdown && dropdownRef.current === e.target) {
            setDropdown(false)
        } else {
            setDropdown(true)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", closeDropdown);
    }, [])

    const logout = () => {
        dispatch(logOut({})).then(() => {
            navigate('/sign-in')
        });
    }
    return (
        <nav className="main-nav--bg">
            <div className="container main-nav">
                <div className="main-nav-start">
                    {props.isSearchable ? <div className="search-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search" aria-hidden="true"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        <input type="text" placeholder={props.queryPlaceholder ? props.queryPlaceholder : "Enter keywords ..."} value={props.query} onChange={props.onSearchInputChange} />
                    </div> : null}
                </div>
                <div className="main-nav-end">
                    <button className="sidebar-toggle transparent-btn" title="Menu" type="button" onClick={(e) => {
                        dispatch(setSidebar(false))
                    }}>
                        <span className="sr-only">Toggle menu</span>
                        <span className="icon menu-toggle--gray" aria-hidden="true"></span>
                    </button>
                    {/* 
                    <div className="lang-switcher-wrapper">
                        <button className="lang-switcher transparent-btn" type="button">
                            EN
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down" aria-hidden="true"><polyline points="6 9 12 15 18 9"></polyline></svg>
                        </button>
                        <ul className="lang-menu dropdown">
                            <li><a href="##">English</a></li>
                            <li><a href="##">French</a></li>
                            <li><a href="##">Uzbek</a></li>
                        </ul>
                    </div>
                    <div className="notification-wrapper">
                        <button className="gray-circle-btn dropdown-btn" title="To messages" type="button">
                            <span className="sr-only">To messages</span>
                            <span className="icon notification active" aria-hidden="true"></span>
                        </button>
                        <ul className="users-item-dropdown notification-dropdown dropdown">
                            <li>
                                <a href="##">
                                    <div className="notification-dropdown-icon info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                    </div>
                                    <div className="notification-dropdown-text">
                                        <span className="notification-dropdown__title">System just updated</span>
                                        <span className="notification-dropdown__subtitle">The system has been successfully upgraded. Read more
                                            here.</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="##">
                                    <div className="notification-dropdown-icon danger">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-info" aria-hidden="true"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                    </div>
                                    <div className="notification-dropdown-text">
                                        <span className="notification-dropdown__title">The cache is full!</span>
                                        <span className="notification-dropdown__subtitle">Unnecessary caches take up a lot of memory space and
                                            interfere ...</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="##">
                                    <div className="notification-dropdown-icon info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check" aria-hidden="true"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                    </div>
                                    <div className="notification-dropdown-text">
                                        <span className="notification-dropdown__title">New Subscriber here!</span>
                                        <span className="notification-dropdown__subtitle">A new subscriber has subscribed.</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="link-to-page" href="##">Go to Notifications page</a>
                            </li>
                        </ul>
                    </div> 
                    
                    */}
                    {/* <button className="theme-switcher gray-circle-btn" type="button" title="Switch theme" onClick={switchDarkMode}>
                        <span className="sr-only">Switch theme</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-sun sun-icon" aria-hidden="true"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-moon moon-icon" aria-hidden="true"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
                    </button> */}
                    <Dropdown>
                        <Dropdown.Toggle
                            className="nav-user-btn dropdown-btn button" as={"button"}>
                            <span className="sr-only">My profile</span>
                            <span className="nav-user-img">
                                <picture>
                                    <source srcSet={avatar_webp} type="image/webp" width={40} />
                                    <img src={avatar_png} alt="User name" width={40} />
                                </picture>
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="users-item-dropdown">
                            <Dropdown.Item href="#/action-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user" aria-hidden="true"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                <span>Profile</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => navigate('/profile')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings" aria-hidden="true"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                <span>Account settings</span>

                            </Dropdown.Item>
                            <Dropdown.Item href="#/log-out" onClick={logout}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out" aria-hidden="true"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                <span>Log Out</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </nav >
    )
}

export default Header;