
import { createSlice } from "@reduxjs/toolkit";

interface LoadingBar {
  value: Boolean;
}
const initialState: LoadingBar = {
  value: false,
}

export const loadingSlice = createSlice({
  name: "loading",
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.value = true;
    },
    completeLoading: (state) => {
      state.value = false;
    },
  },
});

export const { startLoading, completeLoading } = loadingSlice.actions;

export const getLoadingStatus = (state: any) => state.loading.value;

export default loadingSlice.reducer;
