import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import Paginator from "../../components/ui-components/pagination/Paginator";
import useTitle from "../../hooks/useTitle";
import { Link, useNavigate } from "react-router-dom";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import { formateDate } from "../../helper/basic";
import { destroyOffer, getOffers } from "../../store/offer/offerSlice";
import Swal from "sweetalert2";
function Offers() {
    useTitle('Offers');
    const columns: Array<Column> = [
        { label: "Offer Title", accessor: "name" },
        { label: "Code", accessor: "code" },
        { label: "Discount Value", accessor: "coupon_value" },
        { label: "Max Discount", accessor: "max_discount" },
        { label: "Min Order", accessor: "min_order" },
        { label: "Total Quantity", accessor: "total_quantity" },
        { label: "Valid Till Date", accessor: "valid_to" },
        { label: "Action", accessor: "action" },
    ];
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const offers = useAppSelector((state) => state.offers.all);
    const { totalPages } = useAppSelector((state) => state.orders);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    const initialHeaders: { query: string; pageNumber: number } = {
        query: query,
        pageNumber: pageNumber,
    }
    const [apiHeaders, setApiHeaders] = useState(initialHeaders);
    useEffect(() => {
        if (!token) return;
        const params = { query: '', pageNumber: 1, documentLimit: 200 }
        dispatch(getOffers({ params, token }));
    }, [])
    useEffect(() => {
        if (!token) return;
        dispatch(getOffers({ params: apiHeaders, token }));
    }, [apiHeaders]);

    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
        setApiHeaders({ ...apiHeaders, pageNumber: number });
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
        setApiHeaders({ ...apiHeaders, query: e.target.value });
    }
    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyOffer({ id, token }))
            }
        });
    }
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter code and name" onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2 className="main-title mb-0">Offers</h2>
                        <Link to="/offers/create" className="btn btn-info btn-sm">Add Offer</Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {
                                        offers && offers.map((offer: any) => {
                                            return (
                                                <tr key={offer?._id}>
                                                    <td width={"25%"} className="pe-2">
                                                        <div className="d-flex align-items-center gap-2">
                                                            <div>
                                                                <img src={offer?.image} height={"40px"}></img>
                                                            </div>
                                                            <div>
                                                                <h6 className="me-3" title={offer?._id}>
                                                                    {offer?.name ? offer?.name : "N/A"}
                                                                </h6>
                                                                <p> {offer?.description !== "" ? offer?.description : null}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td width={"5%"}>
                                                        <p className="pe-2">
                                                            <h6 className="me-3">
                                                                {offer?.code}
                                                            </h6>
                                                        </p>
                                                    </td>
                                                    <td width={"8%"}>
                                                        <p>
                                                            <h6 className="me-3">
                                                                {
                                                                    offer?.value && offer?.priceType === "percent" ? `${offer?.value}%` : "0"
                                                                }
                                                            </h6>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {
                                                                offer?.maxDiscount ? `C$ ${offer?.maxDiscount}` : 'N/A'
                                                            }
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {
                                                                offer?.cartValue ? `C$ ${offer?.cartValue}` : 'N/A'
                                                            }
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>

                                                                {
                                                                    offer?.quantity ? `Total Quantity:  ${offer?.quantity}` : '0'
                                                                }
                                                            </span>
                                                            <br />
                                                            <span>
                                                                {
                                                                    offer?.redeemedCount ? `Redeemed Count:  ${offer?.redeemedCount}` : '0'
                                                                }
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width={"10%"}>
                                                        <small>{formateDate(offer?.validTo)}</small>
                                                    </td>
                                                    <td >
                                                        <div className="d-flex gap-2">
                                                            <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={() => navigate(`/offers/${offer?._id}`)}>
                                                                Edit
                                                            </Button>
                                                            <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(offer?._id)} >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {(offers && offers.length !== 0) ? null : <NoDataFound colSpan={9} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div >
            </main >
        </MainLayout >
    )
}

export default Offers;