import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { destroyRestaurant, getRestaurants, getRestaurantsFoodCategories } from "../../store/restaurant/restaurantSlice";
import { useEffect, useState } from "react";
import useTitle from "../../hooks/useTitle";
import Paginator from "../../components/ui-components/pagination/Paginator";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import * as formik from 'formik';
import * as yup from 'yup';
import { Formik, Field, ErrorMessage } from "formik";
import { Form } from "react-bootstrap";
import { destroyFoodItem, getFoodItems, storeFoodItem, updateFoodItem } from "../../store/fooditem/foodItemSlice";
import { foodLabelColors } from "../../config/constants";
import Select from "react-select";
import { getCuisines } from "../../store/cuisine/cuisineSlice";
function BulkMenuUpload(props: Props) {
    useTitle("Menu Item Upload")
    const columns: Array<Column> = [
        { label: "Image", accessor: "image" },
        { label: "Name", accessor: "name" },
        { label: "Price", accessor: "price" },
        { label: "Category", accessor: "category" },
        { label: "Rating", accessor: "rating" },
        { label: "Customization", accessor: "customization" },
        { label: "Actions", accessor: "actions" },
    ];
    const initialValues = {
        price: 0,
        rating: 0,
        customizationOption: false,
        name: "",
        description: "",
        foodLabelColor: {
            value: "",
            label: ""
        },
        restaurantID: "",
        image: null,
        isRecommended: false,
        categoryID: {
            value: "",
            label: ""
        }
    }
    const { id } = useParams();
    const [foodItemID, setFoodItemID] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [formInputs, setFormInputs] = useState(initialValues);
    const foodItems = useAppSelector((state) => state.foodItems.all);
    const { totalPages } = useAppSelector((state) => state.foodItems);
    const token = useAppSelector((state) => state.auth.accessToken);
    const { allCategories } = useAppSelector((state) => state.restaurants)
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const validationSchema = yup.object().shape({
        name: yup.string().required("Dish name is a required field."),
        description: yup.string(),
        foodLabelColor: yup.object({
            value: yup.string().required("Meal Type is a required field."),
            label: yup.string().required("Meal Type is a required field.")
        }),
        price: yup.number().min(1, 'Price should be greater than 1').required("Price is required field."),
        isRecommended: yup.boolean(),
        categoryID: yup.object({
            value: yup.string().required("Category is a required field."),
            label: yup.string().required("Category is a required field.")
        }),
        customizationOption: yup.boolean(),
        // image: yup.mixed().required('Dish image is required')
        // rating: 0,
    });


    const onCreate = () => {
        setEditMode(false);
        setModalShow(true);
    }
    const onHide = () => {
        setModalShow(false);
        setEditMode(false);
        setFormInputs(initialValues);
    }

    useEffect(() => {
        const params = { query: query, pageNumber: pageNumber, restaurantID: id };
        if (!token) return;
        dispatch(getFoodItems({ params: params, token }));
        dispatch(getRestaurantsFoodCategories({ params: { restaurantID: id }, token }));
        if (id) {
            setFormInputs({ ...formInputs, restaurantID: id });
        }
    }, [pageNumber, query, token, id]);

    useEffect(() => {

    }, [foodItemID]);


    const categories: any[] = allCategories.map((category: any) => ({ ...category, value: category._id, label: category.name }));
    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
    }
    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyFoodItem({ id, token }));
            }
        });
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files && event.currentTarget.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };
    const onEdit = (id: string) => {
        setFoodItemID(id);
        setModalShow(true);
        setEditMode(true);
        const singleFoodItem = foodItems.find(item => item._id === id);
        const foodLabelColor = foodLabelColors.find(item => item.value === singleFoodItem.foodLabelColor ?? "");
        const singleCategory = categories.find((item) => item._id === singleFoodItem?.foodCategoryID ?? "");
        let customizationOption = false;
        if (singleFoodItem.customizationOptions && singleFoodItem.customizationOptions.length !== 0) {
            customizationOption = true;
        }
        setFormInputs({
            ...formInputs,
            name: singleFoodItem?.name,
            description: singleFoodItem?.description,
            foodLabelColor: foodLabelColor,
            price: singleFoodItem?.price,
            customizationOption: customizationOption,
            categoryID: singleCategory,
            isRecommended: singleFoodItem?.isRecommended,
        });
    }
    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!id) return;
        if (!token) return;
        const formData: FormData = new FormData();
        formData.append('name', data?.name);
        formData.append('description', data?.description);
        formData.append('restaurantID', id);
        formData.append('foodLabelColor', data?.foodLabelColor?.value);
        formData.append('image', selectedFile as Blob);
        formData.append('price', data?.price);
        if (data?.customizationOption) {
            formData.append('customizationOptions[]', data?.customizationOption ? 'spice-level' : '');
        }
        formData.append('isRecommended', data?.isRecommended ? 'true' : 'false');
        formData.append('categoryID', data?.categoryID?.value ? data?.categoryID?.value : null);
        /** Create Mode */
        if (!editMode) {
            dispatch(storeFoodItem({ data: formData, token })).then((data) => {
                if (data.type === "food-item/store/fulfilled") {
                    dispatch(getFoodItems({
                        params: {
                            query: query, pageNumber: pageNumber, restaurantID: id
                        }, token
                    }));
                    setModalShow(false);
                    resetForm();
                }
            });
        } else {
            /***
             * Edit Mode
             */
            dispatch(updateFoodItem({ data: formData, id: foodItemID, token: token })).then((data) => {
                if (data.type === "food-item/update/fulfilled") {
                    dispatch(getFoodItems({
                        params: {
                            query: query, pageNumber: pageNumber, restaurantID: id
                        }, token
                    }));
                    setModalShow(false);
                    resetForm();
                }
            })
        }
    }

    return (
        <MainLayout isSearchable={false} >
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="main-title">Menu Item</h2>
                        <Button variant="primary" onClick={onCreate}>
                            Add Dish
                        </Button>
                    </div>
                    <Row>
                        <Col>
                            <Modal show={modalShow} onHide={onHide}>
                                <Modal.Header closeButton>
                                    <Modal.Title as="h6">  {editMode ? "Edit Dish" : "Add New Dish"}</Modal.Title>
                                </Modal.Header>
                                <Formik
                                    validationSchema={validationSchema}
                                    enableReinitialize={true}
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                    onSubmit={(values, { resetForm }) => {
                                        handleFormSubmit(values, resetForm)
                                    }}
                                    initialValues={formInputs}
                                >
                                    {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                                        <Form noValidate onSubmit={handleSubmit} className='auth-form'>
                                            <Modal.Body>
                                                <Row>
                                                    <Col md={12}>
                                                        <Field
                                                            type="file"
                                                            id="image"
                                                            name="image"
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                handleFileChange(event);
                                                                // setFieldValue('image', event.currentTarget.files![0]);
                                                            }}
                                                        />
                                                        <ErrorMessage name="image" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Label htmlFor='name' className="mb-0">Dish Name</Form.Label>
                                                        <Form.Control id="name" type='text' placeholder="Pizza" name="name" value={values.name}
                                                            onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.name}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Label htmlFor='description' className="mb-0">Description</Form.Label>
                                                        <Form.Control as="textarea" id="description" type='text' placeholder="Veg Pizza with cheese" name="description" value={values.description}
                                                            onChange={handleChange} isValid={touched.description && !errors.description} isInvalid={!!errors.description} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.description}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Label htmlFor='categoryID'>Categories</Form.Label>
                                                        <Select
                                                            className={touched.categoryID && !errors.categoryID ? "basic-single is-invalid" : "border-danger"}
                                                            id="categoryID"
                                                            classNamePrefix="select"
                                                            defaultValue={values.categoryID}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            name="categoryID"
                                                            isMulti={false}
                                                            options={categories}
                                                            onChange={(selectedOption: any) => setFieldValue("categoryID", selectedOption)}
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: !!errors.categoryID ? '#dc3545' : '#ced4da',
                                                                }),
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid" className={!!errors.categoryID ? "d-block" : ""}>
                                                            {errors.categoryID?.value}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Label htmlFor='foodLabelColor' className="mb-0">Meal Type</Form.Label>
                                                        <Select
                                                            className={touched.foodLabelColor && !errors.foodLabelColor ? "basic-single is-invalid" : "border-danger"}
                                                            id="foodLabelColor"
                                                            classNamePrefix="select"
                                                            defaultValue={values.foodLabelColor}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            name="foodLabelColor"
                                                            isMulti={false}
                                                            options={foodLabelColors}
                                                            // value={ }
                                                            onChange={(selectedOption: any) => setFieldValue("foodLabelColor", selectedOption)}
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: !!errors.foodLabelColor ? '#dc3545' : '#ced4da',
                                                                }),
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid" className={!!errors.foodLabelColor?.value ? "d-block" : ""}>
                                                            {errors.foodLabelColor?.value}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Label htmlFor='price' className="mb-0">Price</Form.Label>
                                                        <Form.Control id="price" type='number' name="price" value={values.price}
                                                            onChange={handleChange} isValid={touched.price && !errors.price} isInvalid={!!errors.price} min={1} step={0.1} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.price}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={12}>
                                                        {/* FIXME Need to fix spice level  */}
                                                        <Form.Label htmlFor='customizationOption' className="mb-0">Customization</Form.Label>
                                                        <div className="form-control mb-3">
                                                            <Form.Check reverse type={"checkbox"} id={`check-api-${"checkbox"}`} className="d-flex justify-content-between">
                                                                <Form.Check.Label className={values.customizationOption ? "" : "text-muted"}>Spice level</Form.Check.Label>
                                                                <Form.Check.Input type={"checkbox"} name="customizationOption" checked={values.customizationOption} onChange={handleChange} />
                                                                {/* <Form.Control.Feedback type="valid">
                                                                        You did it!
                                                                    </Form.Control.Feedback> */}
                                                            </Form.Check>
                                                        </div>
                                                        <div className="form-control">
                                                            <Form.Check reverse type={"checkbox"} id={`check-api-${"checkbox"}`} className="d-flex justify-content-between">
                                                                <Form.Check.Label className={values.isRecommended ? "" : "text-muted"}>Recommended</Form.Check.Label>
                                                                <Form.Check.Input type={"checkbox"} name="isRecommended" checked={values.isRecommended} onChange={handleChange} />
                                                                {/* <Form.Control.Feedback type="valid">
                                                                        You did it!
                                                                    </Form.Control.Feedback> */}
                                                            </Form.Check>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="info" type="submit">
                                                    {!editMode ? 'Add Dish' : 'Edit Dish'}
                                                </Button>
                                            </Modal.Footer>
                                        </Form>
                                    )
                                    }
                                </Formik >
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <ThemeTable columns={columns}>
                                <tbody>
                                    {foodItems && foodItems.map((foodItem: any) => {
                                        return (
                                            <tr key={foodItem?._id}>
                                                <td>
                                                    <picture>
                                                        <img src={foodItem.image} alt="category" className="custom" />
                                                    </picture>
                                                </td>
                                                <td className="ps-2 pe-2" width={"30%"}>
                                                    <h6>{foodItem?.name}</h6>
                                                    <p className="m-0">
                                                        {foodItem?.description}
                                                    </p>
                                                </td>
                                                <td width={"10%"}>
                                                    C$ <b>{foodItem?.price}</b>
                                                </td>
                                                <td>

                                                    {foodItem?.foodCategoriesRef?.name}
                                                </td>
                                                <td>
                                                    {foodItem.rating}
                                                </td>
                                                <td>
                                                    {
                                                        foodItem?.customizationOptions && foodItem?.customizationOptions.map((option: any) => {
                                                            return (
                                                                <>
                                                                    <h6>{option?.name} {option?.isRequired ? <span className="text-danger">*</span> : null}</h6>
                                                                    <p className="mb-0">{option?.description}</p>
                                                                    <p className="text-muted"><b>{option?.options?.join(", ")}</b></p>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td style={{ width: "20%" }}>
                                                    <div className="d-flex gap-1">
                                                        <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={(e) => onEdit(foodItem?._id)}>
                                                            Edit
                                                        </Button>
                                                        <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(foodItem?._id)} >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {(foodItems && foodItems.length !== 0) ? null : <NoDataFound colSpan={7} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </Col>
                    </Row>
                </div>
            </main>
        </MainLayout >
    )
}

export default BulkMenuUpload;