import axios, { AxiosResponse, ParamEncoder } from 'axios';

import { API_BASE_URL } from '../../config/constants';
axios.defaults.withCredentials = true;


export const getClustersAPI = async (params: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/clusters`,
    method: 'GET',
    params: params,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}
export const getClusterAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/clusters/${id}`,
    method: 'GET',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}


export const storeClusterAPI = async (data: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/clusters`,
    method: 'POST',
    data: data,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

export const destroyClusterAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/clusters/${id}`,
    method: 'DELETE',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}


export const updateClusterAPI = async (data: Record<string, any>, id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/clusters/${id}`,
    data: data,
    method: 'PUT',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}