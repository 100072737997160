import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { destroyInstructionAPI, getInstructionsAPI, storeInstructionAPI, updateInstructionAPI } from './instructionApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";

export interface IFoodItem {
  all: Array<any>,
  pageNo: number,
  totalPages: number,
  totalResources?: number,
}

const initialState: IFoodItem = {
  all: [],
  pageNo: 1,
  totalPages: 1,
  totalResources: 0,
};

export enum TypePrefix {
  GET_SINGLE = "instruction/get/single",
  GET_ALL = "instruction/get",
  STORE = "instruction/store",
  DESTROY = "instruction/destroy",
  UPDATE = "instruction/update",
}

export const foodItemSlice = createSlice({
  name: "instruction",
  initialState: initialState,
  reducers: {
    setInstructions: (state, action: PayloadAction<any>) => {
      state.all = action.payload.results;
      state.pageNo = action.payload.page_no;
      state.totalPages = action.payload.total_pages;
      state.totalResources = action.payload.total_resources;
    },
    removeInstruction: (state, action: PayloadAction<any>) => {
      const ID = action.payload;
      state.all = state.all.filter(item => item._id !== ID);
    }
  },
});

export const getInstructions = createAsyncThunk<AxiosResponse, { params: Record<string, any>, token: string }, { rejectValue: string }>(
  TypePrefix.GET_ALL,
  async ({ params, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getInstructionsAPI(params, token);
      console.log(response);
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setInstructions(response));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const storeInstruction = createAsyncThunk<AxiosResponse, { data: FormData, token: string }, { rejectValue: string }>(
  TypePrefix.STORE,
  async ({ data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await storeInstructionAPI(data, token);
      if (response.status) {
        /**
         * Success
         * action
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const destroyInstruction = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  TypePrefix.DESTROY,
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await destroyInstructionAPI(id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        thunkAPI.dispatch(removeInstruction(id));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const updateInstruction = createAsyncThunk<AxiosResponse, { data: FormData, id: string, token: string }, { rejectValue: string }>(
  TypePrefix.UPDATE,
  async ({ id, data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await updateInstructionAPI(data, id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);
export const { setInstructions, removeInstruction } = foodItemSlice.actions;
export default foodItemSlice.reducer;
