import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { destroyTimeSlotAPI, getTimeSlotAPI, storeTimeSlotAPI, updateTimeSlotAPI } from './timeSlotApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";

export interface ITimeSlot {
  all: Array<any>
}

const initialState: ITimeSlot = {
  all: [],
};

export const timeSlotSlice = createSlice({
  name: "timeSlot",
  initialState: initialState,
  reducers: {
    setTimeSlot: (state, action: PayloadAction<any>) => {
      state.all = action.payload;
    },
    removeTimeSlot: (state, action: PayloadAction<any>) => {
      const slotID = action.payload;
      state.all = state.all.filter(item => item._id !== slotID);
    }
  },
});


export const getTimeSlots = createAsyncThunk<AxiosResponse, {}, { rejectValue: string }>(
  'timeSlot/get',
  async ({ }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getTimeSlotAPI()
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setTimeSlot(response?.results));
        return response;

      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);


export const storeTimeSlot = createAsyncThunk<AxiosResponse, { data: Record<string, any>, token: string }, { rejectValue: string }>(
  'timeSlot/store',
  async ({ data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await storeTimeSlotAPI(data, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */


        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const destroyTimeSlot = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  'timeSlot/destroy',
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await destroyTimeSlotAPI(id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        thunkAPI.dispatch(removeTimeSlot(id));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const updateTimeSlot = createAsyncThunk<AxiosResponse, { data: Record<string, any>, id: string, token: string }, { rejectValue: string }>(
  'timeSlot/update',
  async ({ id, data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await updateTimeSlotAPI(data, id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);


export const { setTimeSlot, removeTimeSlot } = timeSlotSlice.actions;

export default timeSlotSlice.reducer;
