import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { getContactSupportAPI, destroyContactSupportAPI } from './contactSupportApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";

export interface IContactSupportState {
  all: Array<any>;
  pageNo: number,
  totalPages: number,
  totalResources?: number,
}

const initialState: IContactSupportState = {
  all: [],
  pageNo: 1,
  totalPages: 1,
  totalResources: 0,
};

export enum ContactSupportTypePrefix {
  GET_ALL = "contact-support/get",
  DESTROY = "contact-support/destroy",
}

export const contactSupportSlice = createSlice({
  name: "contact-support",
  initialState: initialState,
  reducers: {
    setContactSupport: (state, action: PayloadAction<any>) => {
      state.all = action.payload.results;
      state.pageNo = action.payload.page_no;
      state.totalPages = action.payload.total_pages;
      state.totalResources = action.payload.total_resources;
    },
    removeContactSupport: (state, action: PayloadAction<any>) => {
      const ID = action.payload;
      state.all = state.all.filter(item => item._id !== ID);
    },
  },
});


export const getContactSupport = createAsyncThunk<AxiosResponse, { params: Record<string, any>, token: string }, { rejectValue: string }>(
  ContactSupportTypePrefix.GET_ALL,
  async ({ params, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getContactSupportAPI(params, token)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setContactSupport(response));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);


export const destroyContactSupport = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  ContactSupportTypePrefix.DESTROY,
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await destroyContactSupportAPI(id, token);
      if (response.status) {
        thunkAPI.dispatch(removeContactSupport(id));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);





export const { setContactSupport, removeContactSupport } = contactSupportSlice.actions;

export default contactSupportSlice.reducer;
