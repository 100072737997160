import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { getRestaurantsAPI, getRestaurantAPI, verifyRestaurantApi, destroyRestaurantAPI, getRestaurantsFoodCategoriesAPI } from './restaurantApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert, successAlert } from "../notifications/toasterSlice";
export interface IRestaurantState {
  all: Array<any>;
  pageNo: number;
  totalPages: number;
  totalResources?: number;
  singleRestaurant: null | Record<string, any>;
  allCategories: Array<any>;
}
const initialState: IRestaurantState = {
  all: [],
  pageNo: 1,
  totalPages: 1,
  totalResources: 0,
  singleRestaurant: null,
  allCategories: []
};

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState: initialState,
  reducers: {
    setRestaurants: (state, action: PayloadAction<any>) => {
      state.all = action.payload.results;
      state.pageNo = action.payload.page_no;
      state.totalPages = action.payload.total_pages;
      state.totalResources = action.payload.total_resources;
    },
    removeRestaurant: (state, action: PayloadAction<any>) => {
      const restaurantID = action.payload;
      state.all = state.all.filter(item => item._id !== restaurantID);
    },
    setSingleRestaurant: (state, action: PayloadAction<any>) => {
      state.singleRestaurant = action.payload;
    },
    setRestaurantFoodCategories: (state, action: PayloadAction<any>) => {
      state.allCategories = action.payload.results;
    },
  },
});


export const getRestaurants = createAsyncThunk<AxiosResponse, { params: Record<string, any>, token: string }, { rejectValue: string }>(
  'restaurant/get',
  async ({ params, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getRestaurantsAPI(params, token)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setRestaurants(response))
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const getRestaurant = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  'restaurant/getSingle',
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getRestaurantAPI(id, token);
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setSingleRestaurant(response?.results));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);


export const verifyRestaurant = createAsyncThunk<AxiosResponse, { data: Record<string, any>, id: string, token: string }, { rejectValue: string }>(
  'restaurant/getSingle',
  async ({ id, data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await verifyRestaurantApi(data, id, token);
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(successAlert(response.message))
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);
export const destroyRestaurant = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  'restaurant/destroy',
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await destroyRestaurantAPI(id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        thunkAPI.dispatch(removeRestaurant(id));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);
export const getRestaurantsFoodCategories = createAsyncThunk<AxiosResponse, { params: Record<string, any>, token: string }, { rejectValue: string }>(
  'restaurant/categories/get',
  async ({ params, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getRestaurantsFoodCategoriesAPI(params, token);
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setRestaurantFoodCategories(response))
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const { setSingleRestaurant, setRestaurants, removeRestaurant, setRestaurantFoodCategories } = restaurantSlice.actions;

export default restaurantSlice.reducer;
