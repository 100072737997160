import axios, { AxiosResponse } from 'axios';

import { API_BASE_URL } from '../../config/constants';
axios.defaults.withCredentials = true;

export const getUsersAPI = async (params: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/users`,
    method: 'GET',
    params: params,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

export const getUserAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/users/${id}`,
    method: 'GET',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

// export const storeTimeSlotAPI = async (data: Record<string, any>, token: string): Promise<AxiosResponse> => {
//   const response = await axios({
//     url: `${API_BASE_URL}/slots`,
//     method: 'POST',
//     data: data,
//     headers: {
//       'x-access-token': token
//     },
//   });
//   return response.data;
// }

export const destroyUserAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/users/${id}`,
    method: 'DELETE',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}


export const updateUserAPI = async (data: Record<string, any>, id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/users/${id}`,
    data: data,
    method: 'PUT',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}