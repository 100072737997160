import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { Col, InputGroup, Row, } from "react-bootstrap";
import useTitle from "../../hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import axios from "axios";
import { getCluster, storeCluster, updateCluster } from "../../store/cluster/clusterSlice";
import { getUser, updateUser } from "../../store/user/userSlice";
import { genders } from "../../config/constants";
function User(props: Props) {
    useTitle("Edit Customer");
    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        gender: {
            value: "",
            label: "-Please Select-"
        },
        birthday: "",
        isVerified: false,
        isActivated: false,
        phoneNumber: "",
        dialCode: "",
        email: ""
    });
    const validationSchema = yup.object().shape({
        firstName: yup.string().required("First name is a required field."),
        lastName: yup.string().required("Last name is a required field."),
        email: yup.string(),
        gender: yup.object().shape({
            value: yup.string().required("Gender is a required field."),
            label: yup.string().required("Gender is a required field.")
        }),
        birthday: yup.string().matches(/^\d{4}-(0[1-9]|1[0-2])-([0-2]\d|3[01])$/, 'Date of birth should be in mm/dd/yyyy').required("Birthday is a required field."),
        isVerified: yup.bool().oneOf([true, false], 'Field must be checked'),
        isActivated: yup.bool().oneOf([true, false], 'Field must be checked'),
        phoneNumber: yup.string().required("First name is a required field."),
        dialCode: yup.string().required("Last name is a required field."),

    });

    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const { singleUser } = useAppSelector((state) => state.users);
    useEffect(() => {
        if (singleUser) {
            console.log(singleUser);

            setInitialValues({
                ...initialValues,
                firstName: singleUser?.firstName,
                lastName: singleUser?.lastName,
                email: singleUser?.email,
                phoneNumber: singleUser?.phoneNumber,
                dialCode: singleUser?.dialCode,
                isActivated: singleUser?.isActivated,
                isVerified: singleUser?.isVerified,
                birthday: singleUser?.birthday,
                gender: {
                    label: singleUser?.gender,
                    value: singleUser?.gender
                },
            });
        }
    }, [singleUser]);

    useEffect(() => {
        if (id) {
            setEditMode(true);
            if (!token) return;
            dispatch(getUser({ id, token }));
        }
    }, [id])

    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!token) return;
        const finalData: Object = {};
        Object.assign(finalData, {
            firstName: data?.firstName,
            lastName: data?.lastName,
            birthday: data?.birthday,
            isVerified: data?.isVerified,
            isActivated: data?.isActivated,
            gender: data?.gender?.value,
            email: data?.email,
        });
        if (editMode && id) {
            dispatch(updateUser({ data: finalData, id, token })).then((data) => {
                navigate("/users");
            })
        }
    }
    return (
        <MainLayout isSearchable={false}>
            <main className="main users chart-page">
                <div className="container">
                    <h2 className="main-title"> {editMode ? "Edit Customer" : "Add Customer"}</h2>
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <Formik
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={(values, { resetForm }) => {
                                    handleFormSubmit(values, resetForm)
                                }}
                                initialValues={initialValues}
                                validateOnBlur={false}
                                validateOnChange={false}
                            >
                                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <fieldset >
                                            <legend>Customer Details:</legend>
                                            <Row className="fieldset-row" >
                                                <Col md={6}>
                                                    <Form.Label htmlFor="firstName">First Name</Form.Label>
                                                    <Form.Control id="firstName" type="text" placeholder="" name="firstName" value={values.firstName}
                                                        onChange={handleChange} isValid={touched.firstName && !errors.firstName} isInvalid={!!errors.firstName} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.firstName}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label htmlFor="lastName">Last Name</Form.Label>
                                                    <Form.Control id="lastName" type="text" placeholder="" name="lastName" value={values.lastName}
                                                        onChange={handleChange} isValid={touched.lastName && !errors.lastName} isInvalid={!!errors.lastName} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.lastName}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label htmlFor="email">Email</Form.Label>
                                                    <Form.Control id="email" type="text" placeholder="" name="email" value={values.email}
                                                        onChange={handleChange} isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label htmlFor="phoneNumber">Mobile Number</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Text id="dialCode">
                                                            {values.dialCode}
                                                        </InputGroup.Text>
                                                        <Form.Control id="phoneNumber" aria-describedby="dialCode" placeholder="" name="phoneNumber" value={values.phoneNumber} isValid={touched.phoneNumber && !errors.phoneNumber} isInvalid={!!errors.phoneNumber} />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label htmlFor="birthday">Date Of Birth</Form.Label>
                                                    <Form.Control id="birthday" type="date" placeholder="" name="birthday" value={values.birthday}
                                                        onChange={handleChange} isValid={touched.birthday && !errors.birthday} isInvalid={!!errors.birthday} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.birthday}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label htmlFor='gender'>Gender</Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        id='gender'
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        isMulti={false}
                                                        name="gender"
                                                        options={genders}
                                                        value={values.gender}
                                                        onChange={(selectedOption: any) => setFieldValue("gender", selectedOption)}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.gender?.value ? "d-block" : ""}>
                                                        {errors.gender?.value}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label htmlFor='isActivated'>Active/Inactivate</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="isActivated"
                                                        label={values.isActivated ? "Active" : "Inactivate"}
                                                        checked={values.isActivated}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.isActivated ? "d-block" : ""}>
                                                        {errors.isActivated}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label htmlFor='isVerified'>Verified/Unverified</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="isVerified"
                                                        label={values.isVerified ? "Verified" : "Unverified"}
                                                        checked={values.isVerified}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.isVerified ? "d-block" : ""}>
                                                        {errors.isVerified}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                        <div className="mt-3 mb-5">
                                            <button className="btn btn-info" type="submit">{editMode ? "Update" : "Submit"} </button>
                                        </div>
                                    </Form>
                                )
                                }
                            </Formik >
                        </Col>
                    </Row>
                </div>
            </main >
        </MainLayout >
    )
}

export default User;