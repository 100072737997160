import axios, { AxiosResponse } from 'axios';

import { API_BASE_URL } from '../../config/constants';
axios.defaults.withCredentials = true;


export const getPostalCodeAPI = async (params: Record<string, any>): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/postal-codes`,
    method: 'GET',
    params: params,
  });
  return response.data;
}
