import { createContext, useState } from "react";
import { IAuthState, IUser } from "../store/auth/authSlice";

interface AuthContextType {
    auth: IAuthState | null;
    persist: boolean;
    setAuth: (user: IUser | undefined, accessToken: string | undefined) => void;
    setPersist: (arg: boolean) => void;
}
const AuthContext = createContext<AuthContextType>({
    auth: null,
    persist: true,
    setAuth: () => { },
    setPersist: () => { }
});


export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [authData, setAuthData] = useState<IAuthState>({
        user: undefined,
        accessToken: undefined,
    });
    const defaultPersist: any = localStorage.getItem('persist');;
    const [persistData, setPersistData] = useState<boolean>(JSON.parse(defaultPersist) ? JSON.parse(defaultPersist) : true);
    const setAuth = (user?: IUser | undefined, accessToken?: string | undefined) => {
        const userDataData = user ? user : authData.user;
        const accessTokenData = accessToken ? accessToken : authData.accessToken;
        setAuthData({
            ...authData,
            user: userDataData,
            accessToken: accessTokenData
        });
    };
    const setPersist = (arg: boolean) => {
        setPersistData(arg)
    };
    return (
        <AuthContext.Provider value={{ auth: authData, setAuth, persist: persistData, setPersist }}>
            {children}
        </AuthContext.Provider>
    );
}
export default AuthContext;