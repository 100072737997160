import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { getZonesAPI, getZoneAPI, storeZoneAPI, destroyZoneAPI, updateZoneAPI } from './zoneApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";

export interface IZone {
  all: Array<any>,
  pageNo: number,
  totalPages: number,
  totalResources?: number,
  singleZone: Record<string, any> | null,
}

const initialState: IZone = {
  all: [],
  pageNo: 1,
  totalPages: 1,
  totalResources: 0,
  singleZone: null,
};

export enum ZoneTypePrefix {
  GET_SINGLE = "zone/get/single",
  GET_ALL = "zone/get",
  STORE = "zone/store",
  DESTROY = "zone/destroy",
  UPDATE = "zone/update",
}

export const zoneSlice = createSlice({
  name: "zone",
  initialState: initialState,
  reducers: {
    setZones: (state, action: PayloadAction<any>) => {
      state.all = action.payload.results;
      state.pageNo = action.payload.page_no;
      state.totalPages = action.payload.total_pages;
      state.totalResources = action.payload.total_resources;
    },
    removeZone: (state, action: PayloadAction<any>) => {
      const zoneID = action.payload;
      state.all = state.all.filter(item => item._id !== zoneID);
    },
    setSingleZone: (state, action: PayloadAction<any>) => {
      state.singleZone = action.payload;
    },
  },
});


export const getZones = createAsyncThunk<AxiosResponse, { params: Record<string, any>, token: string }, { rejectValue: string }>(
  ZoneTypePrefix.GET_ALL,
  async ({ params, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getZonesAPI(params, token)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setZones(response));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const getZone = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  ZoneTypePrefix.GET_SINGLE,
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getZoneAPI(id, token)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setSingleZone(response.results));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const storeZone = createAsyncThunk<AxiosResponse, { data: Record<string, any>, token: string }, { rejectValue: string }>(
  ZoneTypePrefix.STORE,
  async ({ data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await storeZoneAPI(data, token);
      if (response.status) {
        /**
         * Success
         * action
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const destroyZone = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  ZoneTypePrefix.DESTROY,
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await destroyZoneAPI(id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        thunkAPI.dispatch(removeZone(id));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const updateZone = createAsyncThunk<AxiosResponse, { data: Record<string, any>, id: string, token: string }, { rejectValue: string }>(
  ZoneTypePrefix.UPDATE,
  async ({ id, data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await updateZoneAPI(data, id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);


export const { setZones, setSingleZone, removeZone } = zoneSlice.actions;

export default zoneSlice.reducer;
