import { useEffect } from "react"
import { useLocation } from 'react-router-dom';
export default function useTitle(subTitle: string, title?: string) {
    let location = useLocation();
    useEffect(() => {
        return () => {
            if (title) {
                document.title = `${subTitle} - ${title}`;
            } else {
                document.title = `${subTitle} - Free Your Kitchen`;
            }
        }
    }, [title, subTitle, location])
}