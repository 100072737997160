import "./Footer.css";
function Footer(props: any) {
    const current_year = new Date().getFullYear();
    return (
        <footer className="footer">
            <div className="container footer--flex">
                <div className="footer-start">
                    <p>{current_year} © FYK Dashboard -
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            fyk.com
                        </a>
                    </p>
                </div>
                <ul className="footer-end">
                    <li><a href="##">About</a></li>
                    <li><a href="##">Support</a></li>
                    <li><a href="##">Puchase</a></li>
                </ul>
            </div>
        </footer>
    )
}
export default Footer;