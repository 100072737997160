import moment from "moment";
export function formateDate(date: string, format?: string | undefined) {
    const momentObj = moment();
    const utcOffsetString = momentObj.format("Z");
    console.log(utcOffsetString);
    console.log(date);
    console.log(format);
    if (!format) {
        return moment(date).utcOffset(utcOffsetString, true).format('DD MMM YYYY hh:mm A')
    } else {
        return moment(date).utcOffset(utcOffsetString, true).format(format)
    }
}