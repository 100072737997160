import { useState, useEffect } from "react"
import "./Land.css";
import appStoreImg from "../../assets/img/svg/AppStore_black.svg";
import googleStoreImg from "../../assets/img/svg/GooglePlay_black.svg";
import fyk_icon from "../../assets/img/landing/svg/new-fyk-icon.svg";
import halfIphone1 from "../../assets/img/landing/half_iphone_1.png";
import halfIphone2 from "../../assets/img/landing/half_iphone_2.webp";
import halfIphone3 from "../../assets/img/landing/half_iphone_3.webp";
import halfIphoneIcon11 from "../../assets/img/landing/half_iphone_11.webp";
import map from "../../assets/img/landing/map_landing.png";
import newDelBoy from "../../assets/img/landing/new_delivery_boy.webp";
import niggaExplaining from "../../assets/img/landing/foh-boh.webp";
import halfCutPhones from "../../assets/img/landing/half_cut_phones.webp"
import { Link } from "react-router-dom";
import Landing from "./Landing";
import boyWithBag from "../../assets/img/landing/boy_with_lemons.webp"
import { CUSTOMER_APP_STORE_LINK, CUSTOMER_PLAY_STORE_LINK } from "../../config/constants";

// const observer = new IntersectionObserver(entries => {
//     entries.forEach(entry => {
//         if (entry.isIntersecting) {
//             // The second div is in view
//             // Perform your actions here
//             console.log("true")
//         }
//     });
// });

const LandingPage2 = () => {

    // useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 // The second div is in view
    //                 // Perform your actions here
    //                 console.log("true");
    //                 const firstDiv = document.getElementById('first_div');
    //                 if (firstDiv) {
    //                     firstDiv.classList.add('stick');
    //                 }
    //             } else {
    //                 console.log("false");
    //                 const firstDiv = document.getElementById('first_div');
    //                 if (firstDiv) {
    //                     firstDiv.classList.remove("stick")
    //                 }

    //             }
    //         });
    //     });

    //     const secondDiv = document.getElementById('scrollable_div_1');
    //     if (secondDiv) {
    //         observer.observe(secondDiv);
    //     }

    //     // Cleanup the observer when the component unmounts
    //     return () => {
    //         if (secondDiv) {
    //             observer.unobserve(secondDiv);
    //         }
    //     };
    // }, []); 

    return (
        <div className="d-flex flex-column first_top_div">
            <div className="d-flex flex-column top_div1 hero-background " id="first_div">
                <div className="d-flex justify-content-end first_under_top_div1 pt-5 container">
                    <div className="d-flex justify-content-between bcome_and_join">
                        <Link to="/become-a-driver" style={{ textDecoration: "none" }}>
                            <span className="bcome_a_driver " style={{ cursor: "pointer" }}>Become a Driver</span>
                        </Link>

                        <Link to="/join-as-restaurant" style={{ textDecoration: "none" }}>
                            <span className="join_as_restro ps-4" style={{ cursor: "pointer" }}>Join as Restaurant</span>
                        </Link>
                    </div>

                </div>

                <div className="d-flex justify-content-center second_under_top_div1 container">
                    <div className="d-flex flex-column gap-4 ">
                        <div className="d-flex justify-content-center">
                            <div className="outer_circle_top_div ">
                                <img src={fyk_icon} alt="fyk_icon" />
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-4">
                            <div className="d-flex justify-content-center delivered_to">
                                <span>
                                    Delivered to your tray From Miles Away
                                </span>
                            </div>
                            <div className="d-flex justify-content-center bring_your">
                                <span>
                                    Bringing your favourite restaurants closer to you with no excessive fees
                                </span>
                            </div>

                        </div>

                        <div className="d-flex justify-content-center pt-4">
                            <div className="gap-4 d-flex">
                                <a href={CUSTOMER_PLAY_STORE_LINK} style={{ textDecoration: "none" }}>
                                    <div className="d-flex play_store_div gap-2 " style={{ cursor: "pointer" }}>
                                        <div className="d-flex justify-content-center align-items-center first_playstore_appstore">
                                            <img src={googleStoreImg} alt="Playstore" />
                                        </div>
                                        <div className="d-flex flex-column first_playstore_appstore_text">
                                            <span>Available on the</span>
                                            <span>Google Play</span>
                                        </div>
                                    </div>

                                </a>
                                <a href={CUSTOMER_APP_STORE_LINK} style={{ textDecoration: "none" }}>
                                    <div className="d-flex app_store_div gap-2" style={{ cursor: "pointer" }}>
                                        <div className="d-flex justify-content-center align-items-center first_playstore_appstore">
                                            <img src={appStoreImg} alt="AppStore" />
                                        </div>
                                        <div className="d-flex flex-column first_playstore_appstore_text">
                                            <span>Available on the</span>
                                            <span>App Store</span>
                                        </div>
                                    </div>
                                </a>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="d-flex flex-column top_div2 " id="scrollable_div_1">
                <div className="container d-flex flex-column justify-content-center align-items-center first_under_top_div2">

                    <h2>
                        Your food delivery options seem so limited
                    </h2>
                    <p>
                        You're craving the mouth-watering dishes from your favourite restaurant, but the idea of driving across town, waiting for a table, and dealing with noisy crowds dampens your excitement. Plus, they <b className="dont_deliver"> don’t deliver to your area</b>. And just when you thought you could rely on delivery apps, they hit you with steep fees—leaving you questioning whether that delicious meal is worth the extra cost.
                    </p>
                    <h3>
                        What if there was a different way...
                    </h3>
                </div>
                <div className="container d-flex justify-content-between second_under_top_div2 gap-4 ">
                    <div className="first_block_img d-flex justify-content-center align-items-center col-12 ">
                        <span>
                            Favourite eateries are out of reach?
                        </span>
                    </div>

                    <div className="second_block_img d-flex justify-content-center align-items-center col-12 ">
                        <span>
                            Don’t want to pay excessive fees?
                        </span>
                    </div>
                    <div className="third_block_img d-flex justify-content-center align-items-center col-12 ">
                        <span>
                            Tired of inflated prices
                        </span>
                    </div>
                </div>
                <div className="third_under_top_div2 d-flex flex-column gap-4 pb-4">
                    <div className="d-flex justify-content-between first_under_third_under_top_div2">
                        <div className="d-flex flex-column container learn_more_block gap-3 col-12 col-lg-5 pt-5">
                            <h2>Finally, a food delivery solution
                                that satisfies your needs!</h2>
                            <p>Introducing Free Your Kitchen, a new delivery app that eliminates these frustrations and brings your favourite restaurant experience directly to you. </p>
                            <p>Now you can <b className="access_a_variety"> access a variety of cuisines from restaurants </b>that were previously out of reach. We believe in transparency and fairness. That's why we offer our service with <a href="#" className="text-white"> no hidden fees</a>. What you see is what you get – great food, great service, and no surprises when it comes to the cost.</p>
                            <div className="d-flex justify-content-start gap-3">
                                <a href={CUSTOMER_PLAY_STORE_LINK} style={{ textDecoration: "none" }}>
                                    <div className="d-flex play_store_div_black gap-2">
                                        <div className="d-flex justify-content-center align-items-center second_playstore_appstore">
                                            <img src={googleStoreImg} alt="Playstore" />
                                        </div>
                                        <div className="d-flex flex-column second_playstore_appstore_text">
                                            <span>Available on the</span>
                                            <span>Google Play</span>
                                        </div>

                                    </div>
                                </a>
                                <a href={CUSTOMER_APP_STORE_LINK} style={{ textDecoration: "none" }}>
                                    <div className="d-flex app_store_div_black gap-2">
                                        <div className="d-flex justify-content-center align-items-center second_playstore_appstore">
                                            <img src={appStoreImg} alt="AppStore" />
                                        </div>
                                        <div className="d-flex flex-column second_playstore_appstore_text">
                                            <span>Available on the</span>
                                            <span>App Store</span>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div className="d-flex justify-content-center align-items-center col-12 col-lg-6 right_image">
                            <img src={boyWithBag} alt="" />
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-center learn_more_line pb-4">
                        <span >Learn more about our order windows,<b className="Click_learn_more" > Click here </b></span>
                    </div> */}

                </div>
            </div>
            <div className="d-flex flex-column pt-5 top_div3 ">
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex justify-content-center text-center ps-2 pe-2">
                        <h3>Your favourite meals in 3 easy steps</h3>
                    </div>
                    <div className="d-flex col-md-gap-3 gap-3 container half_iphones_conatianer justify-content-between">

                        <div className="half_iphone_1 d-flex  flex-column col-12 ">
                            <div className="first_under_half_iphone_1">
                                <span className="d-flex justify-content-center pb-1 pt-2 first_under_half_iphone_1_span pb-3">PLAN</span>
                                <div className="d-flex flex-column first_div_under_half_iphone_1 gap-3 pb-3" >
                                    <span className="d-flex justify-content-center">Plan a little, save a lot</span>
                                    <p className="d-flex justify-content-center">Download our app and decide on your mealtime for today, choose from our convenient order windows!</p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center align-items-center half_iphone_1_img">
                                <img src={halfIphoneIcon11} alt="halfIphone3" style={{ marginLeft: "30px" }} />
                            </div>
                        </div>


                        <div className="half_iphone_2 d-flex gap-1 flex-column col-12 ">
                            <div className="first_under_half_iphone_1">
                                <span className="d-flex justify-content-center pb-1 pt-2 first_under_half_iphone_1_span pb-3">ORDER</span>
                                <div className="d-flex flex-column first_div_under_half_iphone_2 gap-3 pb-3" >
                                    <span className="d-flex justify-content-center">Order from your favourite
                                        restaurant, even far away</span>
                                    <p className="d-flex justify-content-center">Select from top restaurants from near or far and place your order directly on our app</p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center align-items-center half_iphone_1_img">
                                <img src={halfIphone3} alt="halfIphone3" style={{ width: "98%", paddingTop: "10px" }} />
                            </div>
                        </div>

                        <div className="half_iphone_3 d-flex  flex-column col-12 ">
                            <div className="first_under_half_iphone_1">
                                <span className="d-flex justify-content-center pb-1 pt-2 first_under_half_iphone_1_span pb-3">SAVE</span>
                                <div className="d-flex flex-column first_div_under_half_iphone_3 gap-3 pb-3" >
                                    <span className="d-flex justify-content-center">Save away with no excess
                                        fees</span>
                                    <p className="d-flex justify-content-center">Confirm your order and delivery window. Eat in the comfort of your own home, guilt and hassle free!</p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center align-items-center half_iphone_1_img">
                                <img src={halfIphone2} alt="halfIphone2" style={{ paddingTop: "10px", width: "90%" }} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center pt-3 pb-4 text-center">
                        <span>Learn More about our delivery zones and order windows,<b> <Link to={"/delivery-zones"} style={{ textDecoration: "none", color: '#EB6841' }}> Click here </Link></b></span>
                    </div>
                </div>


                <div className="d-flex map_and_text_container " style={{ backgroundColor: "black" }}>
                    <div className="milton_region_block_1 col-12 col-md-6 gap-3 d-flex justify-content-center">
                        <div className="d-flex flex-column first_under_milton_region_block_1 gap-3">
                            <div className="milton_region_block_1_text d-flex justify-content-center">
                                <h3 className="text-align-left">Now serving the Mississauga & Milton region</h3>
                            </div>
                            <div className="milton_region_block_1_text d-flex justify-content-center">
                                <span className="text-align-left">We’re exciting to be launching this new service to the Mississauga, Milton and surround regions.  We are rapidly expanding and plan to be expanding in new geographic regions across Canada. Check back soon!</span>
                            </div>
                        </div>

                    </div>
                    <div className="landing_page_map col-12 col-md-6">

                        <div className="mapouter">
                            <div className="gmap_canvas"><iframe className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Mississauga&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                <a href="https://strandsgame.net/">Strands NYT</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap top_div4">
                <div className="col-sm-6 black_nigga order-2 order-sm-1">
                    <img src={niggaExplaining} alt="" />
                </div>

                <div className="col-sm-6 join_as_restro_div1 d-flex justify-content-center order-1 order-sm-2">
                    <div className="d-flex flex-column first_under_join_as_restro_div1 gap-3">
                        <div className="text-align-left grow_your_buss_title">
                            <h3>Grow your business
                                with Free Your Kitchen</h3>
                        </div>
                        <div className="text-align-left grow_your_buss_text">
                            <p>Partnering your restaurant with Free Your Kitchen will help to increase profits by increasing accessibility to new customers, improve better margins all while having minimal disruption to your dine-in customers</p>
                        </div>
                        <Link to="/join-as-restaurant">
                            <div className="join_restro_btn">
                                <button> Join as Restaurant  </button>
                            </div>
                        </Link>

                    </div>
                </div>

                <div className="join_as_restro_div2 col-sm-6 d-flex justify-content-center order-3 order-sm-3">
                    <div className="d-flex flex-column  gap-3 first_under_join_as_restro_div2">
                        <div className="text-align-left grow_your_buss_title">
                            <h3>Sign up to Free Your Kitchen and get paid</h3>
                        </div>
                        <div className="text-align-left grow_your_buss_text">
                            <p>Join our delivery team for stable, lucrative, and predictable earnings</p>
                        </div>
                        <Link to="/become-a-driver">
                            <div className="join_restro_btn">
                                <button>  Become a driver</button>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-sm-6 new_delivery_boy order-4 order-sm-4">
                    <img src={newDelBoy} alt="delivery boy" />
                </div>
            </div>
            <div className="d-flex top_div5 pb-3 pb-sm-0">
                <div className="col-sm-6 first_under_top_div5 order-2 order-sm-1">
                    <div className="d-flex justify-content-center just_below_first_under_top_div5">
                        <h4>Order from your favourite restaurants, with the all - new “Free your kitchen” app.</h4>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="d-flex justify-content-start gap-3 google_app_btns_div">
                            <a href={CUSTOMER_PLAY_STORE_LINK} style={{ textDecoration: "none" }}>
                                <div className="d-flex play_store_div_black gap-2" style={{ cursor: "pointer" }}>
                                    <div className="d-flex justify-content-center align-items-center third_playstore_appstore">
                                        <img src={googleStoreImg} alt="Playstore" />
                                    </div>
                                    <div className="d-flex flex-column third_playstore_appstore_text">
                                        <span>Available on the</span>
                                        <span>Google Play</span>
                                    </div>

                                </div>
                            </a>
                            <a href={CUSTOMER_APP_STORE_LINK} style={{ textDecoration: "none" }}>
                                <div className="d-flex app_store_div_black gap-2" style={{ cursor: "pointer" }} >
                                    <div className="d-flex justify-content-center align-items-center third_playstore_appstore">
                                        <img src={appStoreImg} alt="AppStore" />
                                    </div>
                                    <div className="d-flex flex-column third_playstore_appstore_text">
                                        <span>Available on the</span>
                                        <span>App Store</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
                <div className="col-sm-6 half_cut_phones d-flex justify-content-center align-items-center order-1 order-sm-2">
                    <img src={halfCutPhones} alt="" />

                </div>

            </div>
            <Landing />
        </div>
    )
}

export default LandingPage2;