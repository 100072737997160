import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { destroyCuisineAPI, getCuisineAPI, storeCuisineAPI, updateCuisineAPI } from './cuisineApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";


export interface ICuisines {
  all: Array<any>,
  pageNo: number,
  totalPages: number,
  totalResources?: number,
  singleCuisine: Record<string, any> | null,
}

const initialState: ICuisines = {
  all: [],
  pageNo: 1,
  totalPages: 1,
  totalResources: 0,
  singleCuisine: null,
};

export enum CuisineTypePrefix {
  GET_SINGLE = "cuisine/get/single",
  GET_ALL = "cuisine/get",
  STORE = "cuisine/store",
  DESTROY = "cuisine/destroy",
  UPDATE = "cuisine/update",
}

export const cuisinesSlice = createSlice({
  name: "cuisine",
  initialState: initialState,
  reducers: {
    setCuisines: (state, action: PayloadAction<any>) => {
      state.all = action.payload.results;
      state.pageNo = action.payload.page_no;
      state.totalPages = action.payload.total_pages;
      state.totalResources = action.payload.total_resources;
    },
    removeCuisine: (state, action: PayloadAction<any>) => {
      const cuisineID = action.payload;
      state.all = state.all.filter(item => item._id !== cuisineID);
    },
    setSingleCuisine: (state, action: PayloadAction<any>) => {
      // state.singleZone = action.payload;
    },
  },
});


export const getCuisines = createAsyncThunk<AxiosResponse, { params: Record<string, any> }, { rejectValue: string }>(
  CuisineTypePrefix.GET_ALL,
  async ({ params }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getCuisineAPI(params)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setCuisines(response));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);



export const storeCuisine = createAsyncThunk<AxiosResponse, { data: Record<string, any>, token: string }, { rejectValue: string }>(
  CuisineTypePrefix.STORE,
  async ({ data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await storeCuisineAPI(data, token);
      if (response.status) {
        /**
         * Success
         * action
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const destroyCuisine = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  CuisineTypePrefix.DESTROY,
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await destroyCuisineAPI(id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        thunkAPI.dispatch(removeCuisine(id));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const updateCuisine = createAsyncThunk<AxiosResponse, { data: Record<string, any>, id: string, token: string }, { rejectValue: string }>(
  CuisineTypePrefix.UPDATE,
  async ({ id, data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await updateCuisineAPI(data, id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const { setCuisines, removeCuisine } = cuisinesSlice.actions;

export default cuisinesSlice.reducer;
