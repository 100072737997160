import axios, { AxiosResponse } from 'axios';

import { API_BASE_URL } from '../../config/constants';
axios.defaults.withCredentials = true;


export const getCuisineAPI = async (params: Record<string, any>): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/cuisines`,
    method: 'GET',
    params: params
  });
  return response.data;
}


export const storeCuisineAPI = async (data: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/cuisines`,
    method: 'POST',
    data: data,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

export const destroyCuisineAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/cuisines/${id}`,
    method: 'DELETE',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

export const updateCuisineAPI = async (data: Record<string, any>, id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/cuisines/${id}`,
    data: data,
    method: 'PUT',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}