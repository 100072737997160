import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { destroyClusterAPI, getClustersAPI, getClusterAPI, storeClusterAPI, updateClusterAPI } from './clusterApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";

export interface ICluster {
  all: Array<any>,
  pageNo: number,
  totalPages: number,
  totalResources?: number,
  singleCluster: Record<string, any> | null,
}

const initialState: ICluster = {
  all: [],
  pageNo: 1,
  totalPages: 1,
  totalResources: 0,
  singleCluster: null,
};

export const clusterSlice = createSlice({
  name: "cluster",
  initialState: initialState,
  reducers: {
    setClusters: (state, action: PayloadAction<any>) => {
      state.all = action.payload;
    },
    removeCluster: (state, action: PayloadAction<any>) => {
      const clusterID = action.payload;
      state.all = state.all.filter(item => item._id !== clusterID);
    },
    setSingleCluster: (state, action: PayloadAction<any>) => {
      state.singleCluster = action.payload;
    },
  }
});


export const getClusters = createAsyncThunk<AxiosResponse, { params: Record<string, any>, token: string }, { rejectValue: string }>(
  'cluster/get',
  async ({ params, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getClustersAPI(params, token)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setClusters(response?.results));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);
export const getCluster = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  'cluster/get/single',
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getClusterAPI(id, token)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setSingleCluster(response.results));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);


export const storeCluster = createAsyncThunk<AxiosResponse, { data: Record<string, any>, token: string }, { rejectValue: string }>(
  'cluster/store',
  async ({ data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await storeClusterAPI(data, token);
      if (response.status) {
        /**
         * Success
         * action
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const destroyCluster = createAsyncThunk<AxiosResponse, { id: string, token: string }, { rejectValue: string }>(
  'cluster/destroy',
  async ({ id, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await destroyClusterAPI(id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        thunkAPI.dispatch(removeCluster(id));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);
export const updateCluster = createAsyncThunk<AxiosResponse, { data: Record<string, any>, id: string, token: string }, { rejectValue: string }>(
  'cluster/update',
  async ({ id, data, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await updateClusterAPI(data, id, token);
      if (response.status) {
        /**
         * Success
         * action
         * 
         */
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);




export const { setClusters, removeCluster, setSingleCluster } = clusterSlice.actions;

export default clusterSlice.reducer;
