export abstract class AppConfig {
    static readonly APP_NAME: string = process.env.APP_NAME ?? "Free Your Kitchen";
    static readonly API_HOST: string = process.env.REACT_APP_API_HOST ?? "PROBLEM";
    static readonly API_BASE_PREFIX: string = "/api/v1";
}
export const API_BASE_URL = `${AppConfig.API_HOST}${AppConfig.API_BASE_PREFIX}`;
export const ADMIN_ENDPOINT = `${API_BASE_URL}/admin`

export const DELETE_ALERT_PROMPT_CONFIG = {
    title: "<h5 class='mb-0'>Are you sure?</h5>",
    html: "<small>You can't revert back this action.</small>",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "Cancel",
    cancelButtonColor: "#7f7f7f",
    confirmButtonColor: "#dc3545",
    customClass: {
        htmlContainer: "mt-2",
        actions: "mt-2",
    }
}

export const RESTRO_WEB_LOCATION = process.env.REACT_APP_RESTRO_WEB_LOCATION ?? "UNDEFINED";

export const CUSTOMER_PLAY_STORE_LINK = process.env.REACT_APP_CUSTOMER_PLAY_STORE_LINK ?? "https://play.google.com/store/apps/details?id=com.fyk.android.user";
export const CUSTOMER_APP_STORE_LINK = process.env.REACT_APP_CUSTOMER_APP_STORE_LINK ?? "https://apps.apple.com/ca/app/free-your-kitchen/id6449838819";
export const RESTAURANT_PLAY_STORE_LINK = process.env.REACT_APP_RESTAURANT_PLAY_STORE_LINK ?? "https://play.google.com/store/apps/details?id=com.fyk.restaurant.app";
export const RESTAURANT_APP_STORE_LINK = process.env.REACT_APP_RESTAURANT_APP_STORE_LINK ?? "https://apps.apple.com/ca/app/fyk-restaurant/id6451315274";
export const DRIVER_PLAY_STORE_LINK = process.env.REACT_APP_DRIVER_PLAY_STORE_LINK ?? "https://play.google.com/store/apps/details?id=com.fyk.android.driver";
export const DRIVER_APP_STORE_LINK = process.env.REACT_APP_DRIVER_APP_STORE_LINK ?? "https://apps.apple.com/ca/app/fyk-driver/id6468220154";

export const INSTA_LINK = process.env.REACT_APP_INSTA_LINK ?? "https://www.instagram.com/free.your.kitchen?igsh=MWJ4NzBzd21nY2x5MA==";
export const FB_LINK = process.env.REACT_APP_FB_LINK ?? "https://www.facebook.com/share/kmPvZYGYXy6yfmZu/?mibextid=WC7FNe";
export const ADDRESS_MAP_LINK = process.env.REACT_APP_ADDRESS_MAP_LINK ?? "https://www.google.com/maps/search/?api=1&query=140+Yonge+St,+224,+Toronto,+Ontario,+Canada,+M2R1X6";

export const countries: any[] = [{
    "label": "Canada",
    "value": "CA"
}];
export const states: any[] = [
    {
        "label": "Alberta",
        "value": "AB"
    },
    {
        "label": "British Columbia",
        "value": "BC"
    },
    {
        "label": "Manitoba",
        "value": "MB"
    },
    {
        "label": "New Brunswick",
        "value": "NB"
    },
    {
        "label": "Newfoundland and Labrador",
        "value": "NL"
    },
    {
        "label": "Northwest Territories",
        "value": "NT"
    },
    {
        "label": "Nova Scotia",
        "value": "NS"
    },
    {
        "label": "Nunavut",
        "value": "NU"
    },
    {
        "label": "Ontario",
        "value": "ON"
    },
    {
        "label": "Prince Edward Island",
        "value": "PE"
    },
    {
        "label": "Quebec",
        "value": "QC"
    },
    {
        "label": "Saskatchewan",
        "value": "SK"
    },
    {
        "label": "Yukon",
        "value": "YT"
    }
];

export const genders: any[] = [
    {
        "label": "Male",
        "value": "Male"
    },
    {
        "label": "Female",
        "value": "Female"
    },
    {
        "label": "Others",
        "value": "Others"
    }
];

export const foodLabelColors: any[] = [
    {
        "label": "Purely Vegetarian",
        "value": "green"
    },
    {
        "label": "Non Vegetarian",
        "value": "red"
    },
    {
        "label": "Diabetes Friendly",
        "value": "blue"
    },
    {
        "label": "Eggetarian (foods contains eggs)",
        "value": "yellow"
    }
];