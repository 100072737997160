
import "./Loading.css";
import loadingSvg from "../../assets/img/svg/loading.svg"
function Loading(props: any) {
    return (
        <div className="loading">
            Loading...
        </div>
    )

}

export default Loading;