import { useEffect } from "react";

interface PaginationProps {
    pageNo: number,
    totalPages: number,
    totalResources?: 25,
    alignment?: "left" | "right" | "center",
    onPageChange: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, page: number) => void,
}

function Paginator(Props: PaginationProps) {
    let pages = [];
    for (let i = 1; i <= Props.totalPages; i++) {
        pages.push(i);
    }
    let nextPages: any[] = [];
    let prevPages: any[] = [];

    if (Props.pageNo >= 4) {
        nextPages = pages.slice(Props.pageNo, Props.pageNo + 3);
        prevPages = pages.slice(Props.pageNo - 3, Props.pageNo);
    } else {
        nextPages = pages.slice(0, 3);
        prevPages = pages.slice(3, 6);
    }

    if (nextPages.length >= 3 && nextPages.length >= 3) {
        pages = prevPages.concat(nextPages).sort(function (a, b) {
            return a - b;
        });
    } else {
        nextPages = pages.slice(Props.totalPages - 6, Props.totalPages - 3);
        prevPages = pages.slice(Props.totalPages - 3, Props.totalPages);
        pages = prevPages.concat(nextPages).sort(function (a, b) {
            return a - b;
        });
    }
    let className;
    switch (Props.alignment) {
        case "left":
            className = "d-flex justify-content-start";
            break;
        case "right":
            className = "d-flex justify-content-end";
            break;
        case "center":
            className = "d-flex justify-content-center";
            break;
        default:
            className = "d-flex justify-content-end";
    }
    return (
        <nav className={className}>
            <ul className="pagination pagination-sm ">
                <li className="page-item">
                    <span style={{ cursor: "pointer" }} className={Props.pageNo <= 1 ? "page-link disabled" : "page-link "} onClick={(event) => Props.onPageChange(event, Props.pageNo - 1)} aria-label="Previous" >
                        <span aria-hidden="true">&laquo;</span>
                    </span>
                </li>
                {pages && pages.map((page, index) => {
                    return (
                        <li className="page-item" key={index}>
                            <span style={{ cursor: "pointer" }} className={Props.pageNo === page ? "page-link active" : "page-link"} onClick={(event) => Props.onPageChange(event, page)}>{page}</span>
                        </li>)
                })}
                <li className="page-item">
                    <a style={{ cursor: "pointer" }} className={Props.totalPages <= Props.pageNo ? "page-link disabled" : "page-link "} onClick={(event) => Props.onPageChange(event, Props.pageNo + 1)} aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav >);
}

export default Paginator;