import Router from "./Router";
import { useEffect, useRef } from "react";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import "./assets/css/Button.css";
import "./App.css";

import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch, useAppSelector } from "./hooks/redux/hooks";
//@ts-ignore
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import { clearAlert } from "./store/notifications/toasterSlice";

function App() {
  const ref: any = useRef(null);
  const loadingStatus = useAppSelector((state) => state.loading.value);
  const toastNotification = useAppSelector((state) => state.toast);
  const dispatch = useAppDispatch();
  useEffect(() => {
    /**
     * Loading Bar ref...
     */
    if (ref.current) {
      if (loadingStatus && ref.current !== null) {
        ref.current?.continuousStart();
      }
      if (!loadingStatus && ref.current !== null) {
        ref.current.complete();
      }
    }
    /**
     * Global Notifications
     */
    if (toastNotification.message && toastNotification.option.type) {
      /**
       * Remove previous message
       */
      dispatch(clearAlert());
      const option = toastNotification.option as ToastOptions;
      switch (toastNotification.option.type) {
        case "info":
          toast.info(toastNotification.message, option);
          break;
        case "error":
          toast.info(toastNotification.message, option);
          break;
        case "success":
          toast.success(toastNotification.message, option);
          break;
        case "warning":
          toast.warning(toastNotification.message, option);
          break;
        default:
          toast(toastNotification.message, option);
          break;
      }
    }
  }, [loadingStatus, toastNotification.message]);
  return (
    <div className="App">
      <LoadingBar color="#FB6326" ref={ref} shadow={true} height={5} />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Router />
    </div>
  );
}

export default App;
