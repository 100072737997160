import { configureStore, combineReducers, applyMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import storage from 'redux-persist/lib/storage';
import loadingSlice from './loading/loadingSlice';
import authSlice from './auth/authSlice';
import appSlice from './app/authSlice';

import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import toasterSlice from './notifications/toasterSlice';
import restaurantSlice from './restaurant/restaurantSlice';
import timeSlotSlice from './timeslot/timeSlotSlice';
import cuisineSlice from './cuisine/cuisineSlice';
import userSlice from './user/userSlice';
import driverSlice from './driver/driverSlice';
import clusterSlice from './cluster/clusterSlice';
import postalCodeSlice from './postalcode/postalCodeSlice';
import zoneSlice from './zone/zoneSlice';
import foodItemSlice from './fooditem/foodItemSlice';
import orderSlice from './order/orderSlice';
import userFeedbackSlice from './feedback/userFeedbackSlice';
import contactSupportSlice from './contactSupport/contactSupportSlice';
import offerSlice from './offer/offerSlice';
import instructionSlice from './instruction/instructionSlice';
const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [],
    whitelist: ['auth', 'app'],
}


export const rootReducers = combineReducers({
    loading: loadingSlice,
    auth: authSlice,
    toast: toasterSlice,
    app: appSlice,
    restaurants: restaurantSlice,
    timeSlots: timeSlotSlice,
    cuisines: cuisineSlice,
    users: userSlice,
    drivers: driverSlice,
    clusters: clusterSlice,
    postalCodes: postalCodeSlice,
    zones: zoneSlice,
    foodItems: foodItemSlice,
    orders: orderSlice,
    userFeedback: userFeedbackSlice,
    contactSupport: contactSupportSlice,
    offers: offerSlice,
    instruction: instructionSlice
});


const persistedReducer = persistReducer(persistConfig, rootReducers)
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
    //.concat(apiSlice.middleware),
});

setupListeners(store.dispatch)
export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch