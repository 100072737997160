import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startLoading } from "../loading/loadingSlice";
import { AxiosError, AxiosResponse } from 'axios';
import { getPostalCodeAPI } from './postalCodeApi';
import { completeLoading } from "../loading/loadingSlice";
import { errorAlert } from "../notifications/toasterSlice";

export interface IPostalCode {
  all: Array<any>
  totalResources?: number,
}

const initialState: IPostalCode = {
  all: [],
  totalResources: 0,
};

export const postalCodeSlice = createSlice({
  name: "postal-code",
  initialState: initialState,
  reducers: {
    setPostalCode: (state, action: PayloadAction<any>) => {
      state.all = action.payload?.results;
      state.totalResources = action.payload?.total_resources
    }
  },
});


export const getPostalCode = createAsyncThunk<AxiosResponse, { params: Record<string, any> }, { rejectValue: string }>(
  'postal-code/get',
  async ({ params }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response: any = await getPostalCodeAPI(params)
      if (response.status) {
        /**
         * Success
         */
        thunkAPI.dispatch(setPostalCode(response));
        return response;
      } else {
        const err = response as any;
        thunkAPI.dispatch(errorAlert(err.message))
        return thunkAPI.rejectWithValue('');
      }
    } catch (error) {
      const err = error as AxiosError;
      thunkAPI.dispatch(errorAlert(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
    finally {
      thunkAPI.dispatch(completeLoading())
    }
  }
);

export const { setPostalCode } = postalCodeSlice.actions;

export default postalCodeSlice.reducer;
