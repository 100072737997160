

import SignIn from "./components/auth/SignIn";
import LandingPage from "./pages/landing/LandingPage";
import TermsAndConditions from "./pages/landing/TermsAndConditions";
import PrivacyPolicy from "./pages/landing/PrivacyPolicy";

/**
 * Auth Pages
 */
import Dashboard from "./pages/Dashboard";
import Users from "./pages/user/Index";
import Restaurant from "./pages/restaurant";
import VerifiedRestaurants from "./pages/restaurant/verified-restaurant";
import RestaurantDetails from "./pages/restaurant/restaurant-details";
import Slots from "./pages/slot/Index";
import Drivers from "./pages/driver/Index";
import Clusters from "./pages/cluster/Index";
import Cluster from "./pages/cluster/create";
import Zones from "./pages/zone/Index";
import Zone from "./pages/zone/create";
import SingleCluster from "./pages/cluster/view";
import NewRestaurants from "./pages/restaurant/new-restaurant";
import User from "./pages/user/create";
import Profile from "./pages/Profile";
import Driver from "./pages/driver/create";
import Cuisine from "./pages/cuisine/Index";
import BulkMenuUpload from "./pages/restaurant/bulk-menu-upload";
import Orders from "./pages/orders/Index";
import ContactUs from "./pages/landing/ContactUs";
import Landing from "./pages/landing/Landing";
import UserFeedback from "./pages/user-feedback/Index";
import ContactSupport from "./pages/contact-support/Index";
import DriverTransactions from "./pages/transactions/DriverTransactions";
import Offers from "./pages/offers/Index";
import Offer from "./pages/offers/create";
import Instructions from "./pages/instruction/Index";
import LandingPage2 from "./pages/landing/LandingPage2";
import DeliveryZonesPage from "./pages/landing/DeliveryZonesPage";
import JoinAsRestroPage from "./pages/landing/JoinAsRestroPage";
import BecomeADriverPage from "./pages/landing/BecomeADriverPage";
export const DashboardRoutes = [
    {
        path: "/dashboard",
        element: Dashboard,
    },
    {
        path: "/profile",
        element: Profile,
    },
    {
        path: "/users",
        element: Users,
    },
    {
        path: "/users/:id",
        element: User,
    },
    {
        path: "/drivers",
        element: Drivers,
    },
    {
        path: "/drivers/:id",
        element: Driver,
    },
    {
        path: "/restaurants",
        element: Restaurant,
    },
    {
        path: "/new-restaurants",
        element: NewRestaurants,
    },
    {
        path: "/restaurants/verify/:id",
        element: RestaurantDetails,
    },
    {
        path: "/restaurants/edit/:id",
        element: RestaurantDetails,
    },
    {
        path: "/restaurants/edit/bulk-upload/:id",
        element: BulkMenuUpload,
    },
    {
        path: "/verified-restaurant",
        element: VerifiedRestaurants,
    },
    {
        path: "/time-slots",
        element: Slots,
    },
    {
        path: "/instruction",
        element: Instructions,
    },
    {
        path: "/cuisines",
        element: Cuisine,
    },
    {
        path: "/clusters",
        element: Clusters,
    },
    {
        path: "/clusters/create",
        element: Cluster,
    },
    {
        path: "/clusters/:id",
        element: Cluster,
    },
    {
        path: "/clusters/:id/restaurants",
        element: SingleCluster,
    },
    {
        path: "/zones",
        element: Zones,
    },
    {
        path: "/zones/create",
        element: Zone,
    },
    {
        path: "/zones/:id",
        element: Zone,
    },
    {
        path: "/orders",
        element: Orders,
    },
    {
        path: "/user-feedback",
        element: UserFeedback,
    },
    {
        path: "/contact-support",
        element: ContactSupport,
    },
    {
        path: "/drivers/transactions",
        element: DriverTransactions,
    },
    {
        path: "/offers",
        element: Offers,
    },
    {
        path: "/offers/:id",
        element: Offer,
    },
    {
        path: "/offers/create",
        element: Offer,
    },
];
const PageRoutes = [
    {
        path: "/sign-in",
        element: SignIn,
    },
    {
        path: "/",
        element: LandingPage2,
    },
    {
        path: "/delivery-zones",
        element: DeliveryZonesPage
    },
    {
        path: "/landing",
        element: Landing,
    },
    {
        path: "/privacy-policy",
        element: PrivacyPolicy,
    },
    {
        path: "/terms-and-conditions",
        element: TermsAndConditions,
    },
    {
        path: "/contact-us",
        element: ContactUs,
    },
    {
        path: "/join-as-restaurant",
        element: JoinAsRestroPage,
    },
    {
        path: "/become-a-driver",
        element: BecomeADriverPage,
    },

];

export default PageRoutes;