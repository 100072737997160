import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../config/constants';
axios.defaults.withCredentials = true;


export const getOffersAPI = async (params: Record<string, any>, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/offers`,
    method: 'GET',
    params: params,
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

export const storeOfferAPI = async (data: FormData, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/offers`,
    method: 'POST',
    data: data,
    headers: {
      'x-access-token': token,
      'content-type': 'multipart/form-data',
    },
  });
  return response.data;
}


export const getOfferAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/offers/${id}`,
    method: 'GET',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}


export const destroyOfferAPI = async (id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/offers/${id}`,
    method: 'DELETE',
    headers: {
      'x-access-token': token
    },
  });
  return response.data;
}

export const updateOfferAPI = async (data: FormData, id: string, token: string): Promise<AxiosResponse> => {
  const response = await axios({
    url: `${API_BASE_URL}/offers/${id}`,
    data: data,
    method: 'PUT',
    headers: {
      'x-access-token': token,
      'content-type': 'multipart/form-data',
    },
  });
  return response.data;
}