import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import useTitle from "../../hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import { getCluster, storeCluster, updateCluster } from "../../store/cluster/clusterSlice";
import ThemeTable, { NoDataFound, Column } from "../../components/ui-components/table/ThemeTable";
import Paginator from "../../components/ui-components/pagination/Paginator";
import { getRestaurants } from "../../store/restaurant/restaurantSlice";
function SingleCluster(props: Props) {

    useTitle("View Cluster");
    const [initialValues, setInitialValues] = useState({
        name: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: {
            value: "CA",
            label: "Canada"
        },
        clusterID: "",
    });
    const columns: Array<Column> = [
        { label: "Restaurant Name", accessor: "name" },
        { label: "Address", accessor: "address" },
        { label: "Cluster ID", accessor: "cluster_id" },
        { label: "Person Details", accessor: "person_details" },
        { label: "Actions", accessor: "actions" },
    ];
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const { singleCluster } = useAppSelector((state) => state.clusters);
    const restaurants = useAppSelector((state) => state.restaurants.all);
    useEffect(() => {
        if (singleCluster) {
            setInitialValues({
                ...initialValues,
                name: singleCluster?.name,
                street: singleCluster?.address?.street,
                city: singleCluster?.address?.city,
                state: singleCluster?.address?.state,
                zipCode: singleCluster?.address?.zipCode,
                country: {
                    value: singleCluster?.address?.country,
                    label: ""
                },
                clusterID: singleCluster?.clusterID,
            });
        }
    }, [singleCluster]);

    useEffect(() => {
        if (id) {
            if (!token) return;
            dispatch(getCluster({ id, token })).then((data: any) => {
                const clusterID = data.payload?.results?.clusterID;
                dispatch(getRestaurants({ params: { query: "", clusterID: id, documentLimit: 1000 }, token }));
            });
        }
    }, [id]);

    return (
        <MainLayout isSearchable={false}>
            <main className="main users chart-page">
                <div className="container">
                    <h2 className="main-title">{initialValues?.name ?? "Cluster"}</h2>
                    <div>
                        <p className="mb-0"><b className="pe-2 ps-3">{initialValues?.clusterID}</b><br /> {initialValues?.street}  <b className="pe-2 ps-3">City:</b>   {initialValues?.city} , <b className="pe-2 ps-3">State:</b>   {initialValues?.state}, <b className="pe-2 ps-3">Country:</b> {initialValues?.country?.value}  - <b><span className="text-success" title="Postal Code">{initialValues?.zipCode}</span></b></p>
                    </div>
                    <ThemeTable columns={columns} >
                        <tbody>initialValues
                            {restaurants && restaurants.map((restaurant: any) => {
                                return (
                                    <tr key={restaurant?._id}>
                                        <td>
                                            <h6>{restaurant?.name}</h6>
                                            <p className="m-0">{restaurant?.email}</p>
                                        </td>
                                        <td>
                                            {restaurant?.clusterID}
                                        </td>
                                        <td>
                                            {restaurant?.address?.street},<br />
                                            {restaurant?.address?.city}<br />
                                            {restaurant?.address?.state}, {restaurant?.address?.country} - <b>{restaurant?.address?.zipCode}</b>
                                        </td>
                                        <td>
                                            <h6>{restaurant?.restaurantPersonDetails?.firstName} {restaurant?.restaurantPersonDetails?.lastName}</h6>
                                            <b>Email:</b> {restaurant?.restaurantPersonDetails?.email}<br />
                                            <b>Phone:</b>{restaurant?.restaurantPersonDetails?.dialCode} {restaurant?.restaurantPersonDetails?.phoneNumber}
                                        </td>
                                        <td style={{ width: "20%" }}>
                                        </td>
                                    </tr>
                                )
                            })}
                            {(restaurants && restaurants.length !== 0) ? null : <NoDataFound colSpan={5} />}
                        </tbody>
                    </ThemeTable>
                    <Paginator totalPages={1} pageNo={1} onPageChange={console.log} />
                </div>
            </main >
        </MainLayout >
    )
}

export default SingleCluster;