import { useState } from "react";
import "./Aside.css";
import { Link, useLocation } from "react-router-dom";
import { Props } from "../../types";
import { setSidebar } from "../../store/app/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import avatar_png from "./../../assets/img/avatar/avatar-illustrated-02.png";
import avatar_webp from "./../../assets/img/avatar/avatar-illustrated-02.webp";
interface AsideProps extends Props {
    sidebarExpanded: Boolean,
}

function Aside(props: AsideProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { user } = useAppSelector((state) => state.auth)

    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        let targetNode: any = event.target;

        if (event.currentTarget.tagName == "SPAN") {
            targetNode = event.currentTarget.parentNode;
        }
        if (!targetNode) return;
        const targetChildren = targetNode.children;
        const targetParentNode = targetNode.parentNode;
        if (!targetParentNode) return;
        const targetParentNodeChildren = targetParentNode.children;
        for (var i = 0; i < targetChildren.length; i++) {
            if (targetChildren[i].classList.contains('category__btn')) {
                if (targetChildren[i].classList.contains('rotated')) {
                    targetChildren[i].classList.remove('rotated')
                } else {
                    targetChildren[i].classList.add('rotated')
                }
            }
        }
        for (var i = 0; i < targetParentNodeChildren.length; i++) {
            if (targetParentNodeChildren[i].classList.contains('cat-sub-menu')) {
                if (targetParentNodeChildren[i].classList.contains('visible')) {
                    targetParentNodeChildren[i].classList.remove('visible')
                } else {
                    targetParentNodeChildren[i].classList.add('visible')
                }
            }
        }
    }
    return (
        <aside className={props.sidebarExpanded ? "sidebar" : "sidebar hidden"}>
            <div className="sidebar-start">
                <div className="sidebar-head">
                    <a href="/" className="logo-wrapper" title="Home">
                        <span className="sr-only">Home</span>
                        <span className="icon logo" aria-hidden="true"></span>
                        <div className="logo-text">
                            <span className="logo-title">FYK</span>
                            <span className="logo-subtitle">Dashboard</span>
                        </div>
                    </a>
                    <button className={props.sidebarExpanded ? "sidebar-toggle transparent-btn" : "sidebar-toggle transparent-btn rotated"} title="Menu" type="button" onClick={(e) => {
                        dispatch(setSidebar(!props.sidebarExpanded))
                    }}>
                        <span className="sr-only">Toggle menu</span>
                        <span className="icon menu-toggle" aria-hidden="true"></span>
                    </button>
                </div>
                <div className="sidebar-body">
                    <ul className="sidebar-body-menu">
                        <li>
                            {/*  className="active"*/}
                            <Link to="/dashboard" className={location.pathname === "/dashboard" ? "active" : ""}  >
                                <span className="icon home" aria-hidden="true"></span>
                                Dashboard
                            </Link>
                        </li>
                        <li>
                            <Link to="/users" className={location.pathname === "/users" ? "active" : ""}>
                                <span className="icon user-3" aria-hidden="true"></span>
                                Customers
                            </Link>
                        </li>
                        <li>
                            <Link to="/offers" className={location.pathname === "/offers" ? "active" : ""}>
                                <span className="icon discount" aria-hidden="true"></span>
                                Offers
                            </Link>
                        </li>
                        <li>
                            <a className="show-cat-btn" href={void (0)} onClick={handleToggle} >
                                <span className="icon drivers" aria-hidden="true" onClick={handleToggle}></span>
                                Drivers
                                <span className="category__btn transparent-btn rotated" title="Drivers" onClick={handleToggle}>
                                    <span className="sr-only">Drivers</span>
                                    <span className="icon arrow-down" aria-hidden="true"></span>
                                </span>
                            </a>
                            <ul className={(location.pathname === "/drivers" || location.pathname === "/drivers/transactions") ? "cat-sub-menu visible" : "cat-sub-menu"} >
                                <li>
                                    <Link to="/drivers" className={location.pathname === "/drivers" ? "active" : ""}>
                                        All Drivers
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/drivers/transactions" className={location.pathname === "/drivers/transactions" ? "active" : ""}>
                                        Transactions
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/clusters" className={location.pathname === "/clusters" ? "active" : ""} >
                                <span className="icon clusters" aria-hidden="true"></span>
                                Pickup Clusters
                            </Link>
                        </li>
                        <li>
                            <Link to="/zones" className={location.pathname === "/zones" ? "active" : ""} >
                                <span className="icon location" aria-hidden="true"></span>
                                Delivery Zones
                            </Link>
                        </li>
                        <li>
                            <a className="show-cat-btn" href={void (0)} onClick={handleToggle} >
                                <span className="icon restaurant" aria-hidden="true" onClick={handleToggle}></span>
                                Restaurants
                                <span className="category__btn transparent-btn rotated" title="Restaurants" onClick={handleToggle}>
                                    <span className="sr-only">Restaurants</span>
                                    <span className="icon arrow-down" aria-hidden="true"></span>
                                </span>
                            </a>
                            <ul className={(location.pathname === "/restaurants" || location.pathname === "/new-restaurants" || location.pathname === "/verified-restaurant") ? "cat-sub-menu visible" : "cat-sub-menu"} >
                                <li>
                                    <Link to="/restaurants" className={location.pathname === "/restaurants" ? "active" : ""}>
                                        Restaurants
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/new-restaurants" className={location.pathname === "/new-restaurants" ? "active" : ""}>
                                        New Restaurants
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/verified-restaurant" className={location.pathname === "/verified-restaurant" ? "active" : ""}>
                                        Verified Restaurants
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/orders" className={location.pathname === "/orders" ? "active" : ""} >
                                <span className="icon orders" aria-hidden="true"></span>
                                Orders
                            </Link>
                        </li>
                        <li>
                            <Link to="/time-slots" className={location.pathname === "/time-slots" ? "active" : ""} >
                                <span className="icon time-slot" aria-hidden="true"></span>
                                Time Slots
                            </Link>
                        </li>
                        <li>
                            <Link to="/cuisines" className={location.pathname === "/cuisines" ? "active" : ""} >
                                <span className="icon cuisine" aria-hidden="true"></span>
                                Restaurant Type
                            </Link>
                        </li>
                        <li>
                            <Link to="/user-feedback" className={location.pathname === "/user-feedback" ? "active" : ""} >
                                <span className="icon message" aria-hidden="true"></span>
                                Customer Feedback
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact-support" className={location.pathname === "/contact-support" ? "active" : ""} >
                                <span className="icon contact-support" aria-hidden="true"></span>
                                Contact Support
                            </Link>
                        </li>
                        <li>
                            <Link to="/instruction" className={location.pathname === "/instruction" ? "active" : ""}>
                                <span className="icon info-square" aria-hidden="true"></span>
                                App Instruction
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="sidebar-footer">
                <a href="##" className="sidebar-user">
                    <span className="sidebar-user-img">
                        <picture>
                            <source srcSet={avatar_webp} type="image/webp" width={40} />
                            <img src={avatar_png} alt="User name" width={40} />
                        </picture>
                    </span>
                    <div className="sidebar-user-info">
                        <span className="sidebar-user__title">{user?.name}</span>
                        <span className="sidebar-user__subtitle">Admin</span>
                    </div>
                </a>
            </div>
        </aside>
    )
}

export default Aside;
