import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/auth/useAuth";
const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();
    return (
        auth?.user ?
            <Outlet /> :
            auth?.accessToken ?
                <Navigate to="/sign-in" state={{ from: location }} replace /> :
                <Navigate to="/sign-in" state={{ from: location }} replace />
    );
}
export default RequireAuth;